import { PureComponent } from "react"
import React from 'react';

import Editor from "@monaco-editor/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCheckCircle, faTimesCircle } from '@fortawesome/free-solid-svg-icons'
import * as monaco from "monaco-editor"
import "../dashboard/course.css"
import { CodeExecutorWebsocketHandler, getHashCode, getHtmlString,
     getRandomString, promReduxConnect } from "./utils";
import { reduxStateUpdateCodeRunOutput } from "../../store/common.reducer";

export const CODE_EVENT_TYPE_TEXT = "text"
export const CODE_EVENT_TYPE_SELECTION = "selection"
export const CODE_EVENT_TYPE_CURSOR = "cursor"
export const CODE_EVENT_TYPE_INIT = "init"

export const getHighlightRangeForHighlightIndices = (highlightStartIndex, highlightEndIndex, text) => {
    let startLineNumber = -1
    let startColumnNumber = -1
    let endLineNumber = -1
    let endColumnNumber = -1
    let lineNumber = 1
    let columnNumber = 1
    for (let i = 0; i <= (text || "").length; i++) {
        if (highlightStartIndex === i) {
            startLineNumber = lineNumber
            startColumnNumber = columnNumber
        }
        if (highlightEndIndex === i) {
            endLineNumber = lineNumber
            endColumnNumber = columnNumber
        }
        if (text.charAt(i) === "\n") {
            lineNumber = lineNumber + 1
            columnNumber = 1
        } else {
            columnNumber = columnNumber + 1
        }
    }
    return { startLineNumber, startColumnNumber, endLineNumber, endColumnNumber }
}


export const getHighlightIndicesforRange = (startLineNumber, startColumnNumber, endLineNumber, endColumnNumber, text) => {
    let lineNumber = 1
    let columnNumber = 1
    let highlightStartIndex = -1
    let highlightEndIndex = -1
    for (let i = 0; i <= (text || "").length; i++) {
        if (lineNumber === startLineNumber && columnNumber === startColumnNumber) {
            highlightStartIndex = i
        }
        if (lineNumber === endLineNumber && columnNumber === endColumnNumber) {
            highlightEndIndex = i
        }
        if (text.charAt(i) === "\n") {
            lineNumber = lineNumber + 1
            columnNumber = 1
        } else {
            columnNumber = columnNumber + 1
        }
    }
    return { highlightStartIndex, highlightEndIndex }
}
class PythonEditorComponent extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            editedCodeTexts: {},
            outputTabName: "output",
            isOutputExpanded: false
        }
        this.monacoRef = React.createRef()
        this.monacoEditor = React.createRef()

        this.executorId = getRandomString()
        this.codeExecutorWebsocketHandler  = new CodeExecutorWebsocketHandler({
            websocketOnMessage: this.websocketOnMessage, websocketOnOpen: this.websocketOnOpen,
            websocketOnReadyStateChange: this.websocketOnReadyStateChange,
            executorId: this.executorId
        })
    }

    componentDidMount = () => {
        document.addEventListener("keydown", this.onKeydown);
        this.codeExecutorWebsocketHandler.init(this.executorId)
    }
    websocketOnOpen = (webSocket) => {
        if (this.props.onWebsocketConnect) {
            this.props.onWebsocketConnect(webSocket)
        }
        this.outputWebSocket = webSocket
    }
    websocketOnReadyStateChange = (webSocketReadyState)=> {
        this.setState({outputWebSocketReadyState: webSocketReadyState})
    }
    websocketOnMessage = (message) => {
        if (message.messageType === "pythonFileExecutionStatus") {
            this.props.dispatchFunctionAction(reduxStateUpdateCodeRunOutput, {
                runId: message.runId, runOutput: message
            });
            if (this.props.onValidationResultsReceived) {
                this.props.onValidationResultsReceived()
            }
        }
    }
    componentDidUpdate = (prevProps) => {
        const { exerciseId, initialCodeText, highlightBlocks,
            readOnly, initialOutputText } = this.props
        if (exerciseId !== prevProps.exerciseId) {
            this.codeExecutorWebsocketHandler.updateExecutorId(getRandomString())
        }
        if (this.props.editorId !== prevProps.editorId) {
            this.codeExecutorWebsocketHandler.updateExecutorId(getRandomString())
            let isEditTextCleared = false
            const newEditedCodeTexts = {...this.state.editedCodeTexts}
            Object.keys(this.state.editedCodeTexts || {}).forEach(eId => {
                if (eId !== this.props.editorId) {
                    isEditTextCleared = true
                    newEditedCodeTexts[eId] = null
                }
            })
            if (isEditTextCleared) {
                this.setState({editedCodeTexts: newEditedCodeTexts})
            }
        }
        
        if (this?.monacoEditor?.updateOptions) {
            this.monacoEditor.updateOptions({ readOnly: readOnly })
        }
        if (exerciseId !== prevProps.exerciseId || initialCodeText !== prevProps.initialCodeText ||
            highlightBlocks !== prevProps.highlightBlocks) {
            this.setState({runId: null})
            this.updateEditor()
        }
        if (this.lastRenderedOutputText !== this.getOutputText()) {
            this.onOutputTextChanged(this.getOutputText())
        }
        this.lastRenderedOutputText = this.getOutputText()
    }

    componentWillUnmount() {
        document.removeEventListener('keydown', this.onKeydown);
        this.codeExecutorWebsocketHandler.destroy()
    }

    // componentWillUpdate(nextProps, nextState) {
    //     const stateKeys = new Set();
    //     const propsKeys = new Set();
    //     Object.keys(this.props).forEach(key => propsKeys.add(key));
    //     Object.keys(nextProps).forEach(key => propsKeys.add(key));
    //     Object.keys(this.state || {}).forEach(key => stateKeys.add(key));
    //     Object.keys(nextState || {}).forEach(key => stateKeys.add(key));

    //     const getDiff = (keys, object1, object2) => {
    //         const diffValues = [];
    //         const keysArray = [...keys];
    //         keysArray.filter(key => object1[key] !== object2[key]).forEach(key => 
    //             diffValues.push([key, object1[key], object2[key]]));
    //         return [...diffValues];
    //     };

    //     console.log(getDiff(propsKeys, this.props, nextProps),
    //         getDiff(stateKeys, this.state || {}, nextState || {}));
    // }

    toggleOutputExpandMode = () => {
        this.setState({isOutputExpanded: !this.state.isOutputExpanded})
    }

    updateEditor = () => {
        if (!this.monacoEditor || !this.monacoEditor.setValue) {
            return
        }

        this.monacoEditor.setValue(this.getCurrentEditorText())
        this.updateDecorations()
    }

    updateDecorations = () => {
        if (!this.monacoEditor || !this.monacoEditor.setValue) {
            return
        }
        const { editedCodeTexts } = this.state
        if (editedCodeTexts?.[this.props.editorId]) {
            this.monacoEditor.deltaDecorations([], []);
        } else {
            const highlightBlocks = this.props.highlightBlocks || []
            const decorations = []
            highlightBlocks.forEach(highlightBlock => {
                const { highlightStartIndex, highlightEndIndex } = highlightBlock
                if (highlightStartIndex < 0 || highlightEndIndex < 0 || highlightStartIndex === highlightEndIndex) {
                    return
                }
                const { startLineNumber, startColumnNumber, endLineNumber, endColumnNumber } = getHighlightRangeForHighlightIndices(
                    highlightStartIndex, highlightEndIndex, this.getCurrentEditorText())
                decorations.push({ 
                    range: new monaco.Range(startLineNumber, startColumnNumber, endLineNumber, endColumnNumber),
                    options: {
                        inlineClassName: 'myInlineDecoration'
                    }
                })
            })
            this.monacoEditor.deltaDecorations([], decorations);
        }
    }

    getCurrentEditorText = () => {
        const { initialCodeText, savedCodeText } = this.props
        const { editedCodeTexts } = this.state
        return editedCodeTexts?.[this.props.editorId] || savedCodeText || initialCodeText || ""
    }

    onEditorChange = (editorText, event) => {
        const { initialCodeText, highlightBlocks, initialOutputText, editorId, editedCodeTexts } = this.props
        this.setState({editedCodeTexts: {
            ...editedCodeTexts,
            [editorId]: initialCodeText === editorText ? null: editorText
        }}, () => {
            this.updateDecorations()
        })
        if (this.props.onEditorTextChange) {
            this.props.onEditorTextChange(editorText)
        }
    }

    onEditorMount = (editor, monaco) => {
        this.monacoEditor = editor
        this.monacoEditor.updateOptions({
            minimap: {
                enabled: false
            },
            wordWrap: "on",
            readOnly: false,
            contextmenu: this.props.allowCodeCopyPaste === true
        })
        this.monacoEditor.onDidChangeCursorSelection(this.onDidChangeCursorSelection)
        // this.monacoEditor.onDidChangeCursorPosition(this.onDidChangeCursorPosition)
        // this.monacoEditor.onDidChangeModelContent(this.onDidChangeModelContent)
        this.updateEditor()
    }

    onDidChangeCursorSelection = (event) => {
        const selections = this.monacoEditor.getSelections() || []
        if (selections.length < 1) {
            return
        }
        const selection = selections[0]
        const { startLineNumber, startColumn: startColumnNumber, endLineNumber, endColumn: endColumnNumber } = selection
        const { highlightStartIndex, highlightEndIndex } = getHighlightIndicesforRange(
            startLineNumber, startColumnNumber, endLineNumber, endColumnNumber, this.getCurrentEditorText())
        if (this.props.onSelectionChanged && highlightStartIndex !== highlightEndIndex) {
            this.props.onSelectionChanged({ highlightStartIndex, highlightEndIndex })
        }
    }

    setToOutout = () => {
        this.setState({outputTabName: "output"})
    }

    setToExpectedOutout = () => {
        this.setState({outputTabName: "expectedOutput"})
    }

    runCode = () => {
        const editorText = this.getCurrentEditorText()
        const codeHash = getHashCode(editorText)
        this.setState({runId: codeHash})
        const { exerciseId, userId, exercise, courseId } = this.props
        this.outputWebSocket.send(JSON.stringify({
            messageType: "runOrValidatePythonFile",
            runId: codeHash,
            userId: userId,
            shouldValidate: !!exerciseId,
            exerciseId: exerciseId || "",
            pythonFileText: editorText,
            exercise,
            courseId
        }))
    }

    getOutputDoc = (output) => {
        const a = getHtmlString(output)
        
        return `<code style="overflow-wrap: anywhere;">
            ${a}
        </code>`
    }

    onOutputTextChanged = (outputText) => {
        if (this.props.onOutputTextChanged) {
            this.props.onOutputTextChanged(outputText)
        }
    }

    getOutputText = () => {
    //     return `Traceback (most recent call last):
    //     File "/tmp/test_user/python-run.py/1166388157/c2c76b331134477694ddb510f4ca27cb/test_main.py", line 5, in <module>
    //       import test_file
    //     File "/tmp/test_user/python-run.py/1166388157/c2c76b331134477694ddb510f4ca27cb/test_file.py", line 1, in <module>
    //       print(a)
    //   NameError: name 'a' is not defined`
        const { codeRuns, initialOutputText } = this.props
        const { runId } = this.state
        const runOutput = codeRuns[runId]
        let runOutputText = ""
        if (runOutput?.output) {
            runOutputText = runOutputText + runOutput?.output + "\n\n"
        }
        if (runOutput?.error) {
            runOutputText = runOutputText + runOutput?.error
        }
        return runOutputText || (!runId && initialOutputText) || ""
    }

    getValidationTitle = (validation) => {
        const validationType = validation.validationType
        if (validationType === "checkMethodExists") {
            return "Check that the method "
        }

    }

    getValidationResultsElementString = () => {
        const { codeRuns, initialOutputText } = this.props
        const { runId } = this.state
        const validationResults = codeRuns[runId]?.validationResults
        if (!validationResults) {
            return ""
        }
        
        const validations = validationResults?.validations || []
        let validationResultsString = ""
        const totalValidations = validations.length
        let totalPassedValidations = 0
        validations.forEach((validation, index) => {
            const validationId = validation.validationId
            const validationType = validation.validationType
            const validationStatus = validationResults.results?.[validationId]?.status
            const isPrivateValidation = validation?.visibility === "private"
            if (validationStatus === "passed") {
                totalPassedValidations = totalPassedValidations + 1
            }
            let title = ""
            let errorTitle = ""
            // let expandedMessage = ""

            if (validationType === "checkMethodExists") {
                if (validationStatus === "passed") {
                    title = `Verify that method ${validation?.methodName} is defined`
                } else if (validationStatus === "failed") {
                    errorTitle = `Method ${validation?.methodName} is not defined`
                }
            }if (validationType === "checkReturnValue") {
                if (validationStatus === "passed") {
                    title = `Verified return value`
                } else if (validationStatus === "failed") {
                    if (isPrivateValidation) {
                        errorTitle = `Invalid return value for ${validation?.methodName} (Private test case)`
                    } else  {
                        errorTitle = `Invalid return value for ${validation?.methodName}.
                        ${validationResults.results?.[validationId]?.message}`
                    }
                }
            } else {
                if (validationStatus === "passed") {
                    title = `Test ${validationId}/${validationType} passed`
                } else if (validationStatus === "failed") {
                    errorTitle = `Test ${validationId}/${validationType} failed`
                }
            }

            if (validationStatus === "failed") {
                validationResultsString = `${validationResultsString}\n
                <div style="color: red; border: 1px solid black; margin: 3px">${index+1}. ${errorTitle}</div>`
            } else if (validationStatus === "passed") {
                validationResultsString = `${validationResultsString}\n
                <div style="color: green; border: 1px solid black; margin: 3px">${index+1}. ${title}</div>`
            } else {
                validationResultsString = `${validationResultsString}\n
                <div style="color: gray; border: 1px solid black; margin: 3px">${index+1}. 
                Test ${validationId}/${validationType} not run</div>`
            }
        })

        return `<div>
            ${validationResults.overallStatus === "passed" ?
                `<div style="color: green">Passed</div>`:
                `<div style="color: red">Failed</div>
                <div style="color: red">${this.getOutputDoc(validationResults.overallError || "")}</div>`
            }
            
            <div>Tests passed ${totalPassedValidations}/${totalValidations}</div>
            ${validationResultsString}
        </div>`
    }

    onKeydown = (event) => {
        const { allowCodeCopyPaste } = this.props;
        const key = event.key.toLowerCase();
        if (allowCodeCopyPaste !== true && event.ctrlKey && key === 'v') {
            // event.preventDefault();
        }
    }

    render = () => {
        const { 
            expectedOutputText, allowCodeCopyPaste,
            taskStatus, exerciseId, timeSpentMillis,
            lastUpdateTimestampMillis, exercise, courseId, isExam } = this.props
        const height = this.props.height || "150px"

        const { outputWebSocketReadyState, outputTabName } = this.state
        
        const currentEditorText = this.getCurrentEditorText()
        if (currentEditorText === null) {
            return null
        }
        
        return (<div className="d-flex h-100 w-100" 
            style={{
                backgroundColor: "rgba(0, 80, 250, 0.05)",
            // border: "1px solid gray",
                borderRadius: "3px",
                padding: "5px 10px 5px 10px",
                minHeight: "30rem"
                // paddingTop: "10px"
            }}>
            {/* <div className="row container-fluid"> */}
                <div className="d-flex flex-column" style={{flex: "50%"}}>
                    <div className="d-flex flex-row navBar navBarPythonEditor align-items-center" style={{
                        paddingRight: "10px",
                    }}>
                        <div className="" style={{marginLeft: "10px"}}>Python</div>
                        <div className="ms-auto">
                            {exerciseId}
                        </div>
                        <div className="ms-auto"></div>
                        <div className="me-2" style={{
                            marginRight: "3px",
                            color: outputWebSocketReadyState === 1 ? "green": "red"}}>
                                {outputWebSocketReadyState === 1 ? "Connected": "Not connected"}
                        </div>
                        <div className="primaryActionButton ml-2" onClick={this.runCode}
                            disabled={outputWebSocketReadyState !== 1}>Run</div>
                        {/* <div className="d-flex flex-row" style={{marginRight: "10px"}}>
                            
                            {timeSpentMillis > 0 ? <div style={{
                                lineHeight: "normal",
                                fontSize: "10px",
                            }}>
                                {getDurationString(timeSpentMillis)}
                            </div>: null }
                            </div>
                        </div> */}
                        {/* {!exerciseId ?
                            <li className="nav-item ms-auto">
                                <button className="btn btn-primary ml-2" onClick={this.runCode}>Run</button>
                            </li>: null
                        } */}
                    </div>
                    <div className="flex-grow-1 d-flex flex-column" style={{}}>
                        <div style={{height: "100%", overflow: "hidden"}}>
                            <Editor
                                // style={{overflow: "hidden"}}
                                height={"70vh"}
                                width={"97%"}
                                // height=calc(100% - 35px)
                                defaultLanguage="python"
                                allowCodeCopyPaste={allowCodeCopyPaste}
                                onChange={this.onEditorChange}
                                onMount={this.onEditorMount}
                            />
                        </div>
                        {/* <div style={{height: "5px", backgroundColor: "white"}}></div> */}
                    </div>
                    {/* // : null} */}
                </div>
                <div className="d-flex flex-column" style={{flex: "50%"}}>
                    <div className="d-flex align-items-stretch navBar navBarPythonEditor">

                        <div className={
                            outputTabName === "output" ?
                            "pythonEditorNavItem pythonEditorNavItemActive":
                            "pythonEditorNavItem"}
                            onClick={this.setToOutout}>Output</div>
                        {exerciseId ? 
                        <div className={
                            outputTabName === "expectedOutput" ?
                            "pythonEditorNavItem pythonEditorNavItemActive":
                            "pythonEditorNavItem"}
                            onClick={this.setToExpectedOutout}>Expected Output</div>: null}
                        {exerciseId && !isExam ? 
                        <div className={
                            outputTabName === "validationResults" ?
                            "pythonEditorNavItem pythonEditorNavItemActive":
                            "pythonEditorNavItem"}
                            onClick={() => this.setState({outputTabName: "validationResults"})}>
                                Results</div>: null}
                        {exerciseId && !isExam ? 
                        <div className="ms-auto d-flex align-items-center">
                            <div style={{
                                        lineHeight: "normal",
                                        fontSize: "10px",
                                        color: taskStatus === "passed" ? "green": "red"
                                    }}>
                                    {lastUpdateTimestampMillis > 0 ? 
                                    new Date(lastUpdateTimestampMillis).toLocaleString("en-IN"): ""}
                            </div>

                            <div>
                                {taskStatus === "passed" ?
                                <FontAwesomeIcon icon={faCheckCircle} color={"green"}
                                    size={"lg"}
                                    style={{marginLeft: "10px"}}/>:
                                <FontAwesomeIcon icon={faTimesCircle} color={"red"}
                                    size={"lg"}
                                    style={{marginLeft: "10px"}}/>}
                            </div>
                        </div>: null}
                    </div>
                    {/* <div className="flex-fill d-flex align-items-stretch"> */}
                        {/* <div className="col-12"> */}
                    {this.state.outputTabName === "output" ? 
                    <iframe className="flex-fill" style={{backgroundColor: "white"}} srcDoc={this.getOutputDoc(
                        this.getOutputText())} title="output"></iframe> :
                    this.state.outputTabName === "expectedOutput" ?
                    <iframe className="flex-fill" style={{backgroundColor: "white"}} 
                    srcDoc={this.getOutputDoc(expectedOutputText)}
                        title="output"></iframe>:
                    <iframe className="flex-fill" style={{backgroundColor: "white"}} 
                    srcDoc={this.getValidationResultsElementString()}
                        title="output"></iframe>    
                    }
                        {/* </div> */}
                    {/* </div> */}
                </div>
            {/* </div> */}
        </div>)
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        email: state.common.user?.email,
        userId: state.common.user?.userId,
        codeRuns: state.common.codeRuns
    };
};

export const PythonEditor = promReduxConnect(PythonEditorComponent,
    mapStateToProps
)