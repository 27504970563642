import { put, takeEvery } from "redux-saga/effects";
import rawaxios from 'axios';
import { promAxios } from './promAxios';
import { getHashCode } from "../components/util/utils";
import { reduxStateUpdateCourseTask, reduxStateUpdateCourseTasks, reduxStateUpdateCourses,
    reduxStateUpdateExamExam, reduxStateUpdateExercise, reduxStateUpdateLessonContent, reduxStateUpdateLoginStatus, 
    reduxStateUpdateRunHttpApi,registeredReducerFunctions, reduxStateUpdateBatches, reduxStateUpdateSubscriptions, reduxStateUpdateMyOrders,
    reduxStateUpdateLogout, reduxStateUpdateLoginApiCredentials } from "./common.reducer";
   
export const getReducerState = (state) => state.common;

const promPut = (reducerFunction, payload) => {
    // registeredReducerFunctions.add(reducerFunction)
    return put({
        type: getHashCode(reducerFunction.toString()),
        payload
    })
}

export const saveExerciseInSync = (courseId, taskId, courseTaskData, dispatchFunctionAction) => {
    console.log("saveExerciseInSync")
    const apiEndpoint = `/api/v1/courseTasks?courseId=${courseId}&taskId=${taskId}`;
    return new Promise((resolve, reject) => {
        promAxios.post(apiEndpoint, courseTaskData).then(response => {
            if (dispatchFunctionAction) {
                dispatchFunctionAction(reduxStateUpdateCourseTask, {
                    courseId, taskId, courseTask: response.data
                })
            }
            resolve(response.data)
        })
    })
}

export function* saveCourseTaskSaga({ courseId, taskId, courseTaskData }) {
    console.log("saveCourseTaskSaga")
    const apiEndpoint = `/api/v1/courseTasks?courseId=${courseId}&taskId=${taskId}`;

    const response = yield promAxios.post(apiEndpoint, courseTaskData);
    if (response && response.status === 200) {
        yield promPut(reduxStateUpdateCourseTask, {
            courseId, taskId, courseTask: response.data
        });
    }
}

export function* runHttpApiSaga({
    runId, httpUrl, httpRequestMethod, httpRequestBody="",
    httpRequestHeaders=[] }) {        
    try {
        const headers = {}
        httpRequestHeaders.forEach(httpRequestHeader => {
            if (httpRequestHeader.httpRequestHeaderKey) {
                headers[httpRequestHeader.httpRequestHeaderKey] =
                    httpRequestHeader.httpRequestHeaderValue || ""
            }
        })
        let response = null
        if (httpRequestMethod === "GET") {
            response = yield rawaxios.get(httpUrl, {
                headers: headers
            });
        } else if (httpRequestMethod === "POST") {
            let jsonBody = null
            try {
                jsonBody = JSON.parse(httpRequestBody || "{}")
            } catch (e) {
                yield promPut(reduxStateUpdateRunHttpApi, {
                    runId, httpResponseStatus: -1,
                    httpResponseBody: e?.response?.statusText || `Failed to parse request. ${e}`
                });
            }
            if (jsonBody) {
                response = yield rawaxios.post(httpUrl, jsonBody, {
                    headers: headers
                });
            }
        } else if (httpRequestMethod === "DELETE") {
            response = yield rawaxios.delete(httpUrl, {
                headers: headers
            });
        }
        
        if (response && response.status === 200) {
            yield promPut(reduxStateUpdateRunHttpApi, {
                runId, httpResponseStatus: response.status,
                httpResponseBody: response.data
            });
        }
    } catch (e) {
        console.log(e)
        yield promPut(reduxStateUpdateRunHttpApi, {
            runId, httpResponseStatus:  e?.response?.status || -1,
            httpResponseBody: e?.response?.statusText || ""
        });
    }
}

export function* getCourseTaskSaga({ courseId, taskId }) {
    const apiEndpoint = `/api/v1/courseTasks?courseId=${courseId}&taskId=${taskId}`;

    const response = yield promAxios.get(apiEndpoint);
    if (response && response.status === 200) {
        yield promPut(reduxStateUpdateCourseTask, {
            courseId, taskId, courseTask: response.data
        })
    }
}

export function* getCourseTasksSaga({ courseId }) {
    const apiEndpoint = `/api/v1/allCourseTasks?courseId=${courseId}`;

    const response = yield promAxios.get(apiEndpoint);
    if (response && response.status === 200) {
        yield promPut(reduxStateUpdateCourseTasks, {
            courseId, tasks: response.data.tasks
        });
    }
}

export function* resetExerciseSaga({ courseId, exerciseId }) {
    const apiEndpoint = `/api/v1/exerciseTemplateInit?courseId=${courseId}&exerciseId=${exerciseId}`;

    const response = yield promAxios.post(apiEndpoint, {
    });
    yield promPut(reduxStateUpdateCourseTask, {
        courseId, taskId: exerciseId, courseTask: response.data
    })
}

const promSagaGeneratorWrapper = (generatorFunction) => {
    const sagaGenerator = function* (action) {
        try {
            const { payload } = action;
            yield* generatorFunction(payload)
        } catch (e) {
            console.log(e)
        }
    }
    return sagaGenerator
}

const promTakeEvery = (generatorFunction) => {
    return takeEvery(
        getHashCode(generatorFunction.toString()),
        promSagaGeneratorWrapper(generatorFunction)
    )
}

export function* checkLoginSaga({}) {
    try {        
        const response = yield promAxios.get(`/api/v1/checkLogin`);
        yield promPut(reduxStateUpdateLoginStatus, {
            loginStatus: "loggedIn", user: response.data.user
        });
        yield promPut(reduxStateUpdateSubscriptions, {
            subscriptions: response.data.subscriptions
        });
    } catch (e) {
        if(e?.response?.status === 401) {
            yield promPut(reduxStateUpdateLoginStatus, {
                loginStatus: "notLoggedIn", user: null
            });
        }
    }
}

export function* loginSaga({ email, password }) {
    try{
        const apiEndpoint = '/api/v1/login';
        const response = yield promAxios.post(apiEndpoint, {email, password});
        localStorage.setItem("jwtToken", response.data?.jwtToken)
        const jwtToken = response.data.jwtToken
        yield promPut(reduxStateUpdateLoginStatus, {
            loginStatus: "loggedIn", user: response.data.user
        });
        yield promPut(reduxStateUpdateSubscriptions, {
            subscriptions: response.data.subscriptions
        });
        promAxios.defaults.headers.common.Authorization = `Bearer ${jwtToken}`;
        yield promPut(reduxStateUpdateLoginApiCredentials, {
            loginApiAreInvalidCredentials: false,
            loginApiHasServerError: false
        })
    }
    catch (e) {
        if (e?.response?.status === 401) {
            yield promPut(reduxStateUpdateLoginApiCredentials, {
                loginApiAreInvalidCredentials: true,
                loginApiHasServerError: false
            })
        }
        else {
            yield promPut(reduxStateUpdateLoginApiCredentials, {
                loginApiAreInvalidCredentials: false,                
                loginApiHasServerError: true
            })
        }
    }
}

export function* logoutSaga() {
    localStorage.removeItem('jwtToken')
    yield promPut(reduxStateUpdateLogout, { });
    const response = yield promAxios.get(`/api/v1/logout`);
    // yield put(setLoginStatusReducerAction("notLoggedIn", null));
}

export function* getCourseSaga({ courseId }) {
    let apiEndpoint = '/api/v1/courses';
    if (courseId) {
        apiEndpoint = `/api/v1/courses/${courseId}`
    }

    const response = yield promAxios.get(apiEndpoint);
    if (response && response.data) {
        if (courseId) {
            yield promPut(reduxStateUpdateCourses, {
                courses: {
                    [courseId]: response.data
                }
            })
        } else {
            yield promPut(reduxStateUpdateCourses, {
                courses: response.data.courses
            })
        }
    }
}

export function* getBatchesSaga({ }) {
    const response = yield promAxios.get(`/api/v1/batches`);
    if (response && response.data) {
        yield promPut(reduxStateUpdateBatches, {
            batches: response.data.batches
        })
    }
}

export function* getSubscriptionsSaga({ }) {
    const response = yield promAxios.get(`/api/v1/subscriptions`);
    yield promPut(reduxStateUpdateSubscriptions, {
        subscriptions: response.data.subscriptions
    })
}

export function* getLessonContentSaga({ lessonId }) {
    let apiEndpoint = `/api/v1/lessonContents/${lessonId}`;

    const response = yield promAxios.get(apiEndpoint);
    if (response && response.data) {
        yield promPut(reduxStateUpdateLessonContent, {
            [lessonId]: response.data
        })
    }
}

export function* getMyOrdersSaga({ }) {
    const response = yield promAxios.get(`/api/v1/orders`);
    yield promPut(reduxStateUpdateMyOrders, {
        orders: response.data.orders
    })
}

export function* getExamSaga({ examId }) {
    let apiEndpoint = `/api/v1/exams/${examId}`;

    const response = yield promAxios.get(apiEndpoint);
    if (response && response.data) {
        yield promPut(reduxStateUpdateExamExam, {
            [examId]: response.data
        })
    }
}
export function* getExerciseSaga({ exerciseId, courseId }) {
    let apiEndpoint = `/api/v1/exercises/${exerciseId}?courseId=${courseId}`;

    const response = yield promAxios.get(apiEndpoint);
    yield promPut(reduxStateUpdateExercise, {
        [exerciseId]: response.data?.exercise
    })
    if (response?.data?.courseTask) {
        yield promPut(reduxStateUpdateCourseTask,{
            courseId , taskId : exerciseId, courseTask : response?.data?.courseTask
        })
    }
}
export function* validateQuestionSaga({ exerciseId, courseId, questionData }) {
    let apiEndpoint = `/api/v1/exercises/${exerciseId}/validateQuestion?courseId=${courseId}`;

    const response = yield promAxios.post(apiEndpoint, {questionData});
    if (response && response.data) {
        yield promPut(reduxStateUpdateCourseTask, {
            courseId, taskId: exerciseId, courseTask: response.data
        })
    }
}

export function* watchRequests() {
    const registeredSagasList = [
        loginSaga, checkLoginSaga, logoutSaga, getCourseTaskSaga,
        getCourseTasksSaga, saveCourseTaskSaga, runHttpApiSaga,
        resetExerciseSaga, getCourseSaga, getExamSaga,
        getLessonContentSaga, getExerciseSaga, validateQuestionSaga,
        getBatchesSaga, getSubscriptionsSaga, getMyOrdersSaga
    ]
// console.log({registeredSagas})
    // const registeredSagasList = [...registeredSagas]
    for (let i = 0; i < registeredSagasList.length; i++) {
        yield promTakeEvery(registeredSagasList[i]);
    }
}