import { PureComponent } from "react"
import React from 'react';

import { connect } from "react-redux";
import { QuestionTextComponent } from "./utils";
import { PythonEditor } from "./pythonEditor";
import "../dashboard/course.css";


export class PythonExerciseViewerComponentInternal extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            playIntervalSeconds: 10,
            mode: "answer"
        }
    }

    componentDidMount = () => {
    }

    componentDidUpdate = (prevProps, prevState) => {
        const { playIntervalSeconds } = this.state
    }


    // componentWillUpdate(nextProps, nextState) {
    //     const stateKeys = new Set();
    //     const propsKeys = new Set();
    //     Object.keys(this.props).forEach(key => propsKeys.add(key));
    //     Object.keys(nextProps).forEach(key => propsKeys.add(key));
    //     Object.keys(this.state || {}).forEach(key => stateKeys.add(key));
    //     Object.keys(nextState || {}).forEach(key => stateKeys.add(key));

    //     const getDiff = (keys, object1, object2) => {
    //         const diffValues = [];
    //         const keysArray = [...keys];
    //         keysArray.filter(key => object1[key] !== object2[key]).forEach(key => diffValues.push([key, object1[key], object2[key]]));
    //         return [...diffValues];
    //     };

    //     console.log(getDiff(propsKeys, this.props, nextProps), getDiff(stateKeys,
    // this.state || {}, nextState || {}));
    // }

    movePrevious = () => {
        const { playEventIndex } = this.state
        if (playEventIndex > 0) {
            this.setState({playEventIndex: playEventIndex - 1})
        }
    }

    moveNext = () => {
        const { playEventIndex, playEvents } = this.state
        if (playEventIndex < playEvents.length - 1) {
            this.setState({playEventIndex: playEventIndex + 1})
        }
    }

    onEditorTextChange = (editedText) => {
        if (this.props.onExerciseTaskProgressChanged) {
            this.props.onExerciseTaskProgressChanged({files: [{filePath: "main.py", fileText: editedText}]})
        }
    }


    onValidationResultsReceived = () => {
        // const { exercise } = this.props
        // const {
        //     initialCodeText, expectedOutputText,
        //     exerciseId, questionHtml,
        //     allowCodeCopyPaste } = exercise || {}
        // const { overallError, overallOutput } = validationResults

        // const areOutputsEqual = (!overallOutput && !expectedOutputText) ||
        //     (overallOutput && expectedOutputText && overallOutput.trim() === expectedOutputText.trim())

        // const updatExerciseData = {
        //     files: [{filePath: "main.py", fileText: exerciseCodeText}], validationResults}


        // if (overallError) {
        //     updatExerciseData.taskStatus = "failed"
        // } else if (!areOutputsEqual) {
        //     updatExerciseData.taskStatus = "failed"
        // } else {
        //     updatExerciseData.taskStatus = "passed"
        // }
        // this.props.onSaveExercise(updatExerciseData)
    }

    render = () => {
        const { exercise, courseId, exerciseTask, isExam } = this.props
        const {
            initialCodeText, expectedOutputText,
            exerciseId, questionHtml,
            allowCodeCopyPaste } = exercise || {}

        if (!exercise) {
            return null
        }

        return (<div className="d-flex flex-column h-100 w-100" style={{}}>
            <QuestionTextComponent exercise={exercise} exerciseTask={exerciseTask}></QuestionTextComponent>

            <PythonEditor
                courseId={courseId}
                editorId={`${exercise?.exerciseId}`}
                initialCodeText={initialCodeText}
                highlightBlocks={null}
                expectedOutputText={expectedOutputText}
                height={"450px"}
                exerciseId={exerciseId}
                savedCodeText={exerciseTask?.files?.[0]?.fileText}
                showOutput={true}
                exercise={exercise}
                lastUpdateTimestampMillis={exerciseTask?.updateTimestampMillis}
                taskStatus={exerciseTask?.taskStatus}
                timeSpentMillis={exerciseTask?.timeSpentMillis}
                onEditorTextChange={this.onEditorTextChange}
                isExam={isExam}
                onValidationResultsReceived={this.props.onValidationResultsReceived}
                allowCodeCopyPaste={allowCodeCopyPaste}>
            </PythonEditor>
        </div>)
    }
}

const mapStateToPropsPythonAnswerQuizLessonPart = (state, ownProps) => {
    return {
        email: state.common.user?.email,
        userId: state.common.user?.userId,
    };
};

const mapDispatchToPropsPythonAnswerQuizLessonPart = dispatch => ({
});

export const PythonExerciseViewerComponent = connect(
    mapStateToPropsPythonAnswerQuizLessonPart,
    mapDispatchToPropsPythonAnswerQuizLessonPart,
)(PythonExerciseViewerComponentInternal)