import { PureComponent } from "react"
import { getCourseSubscription, getCourseWithTaskStatus, getDateString, isCourseAlreadySubscribed, isLessonTask,
     isProjectExercise, isProjectLesson, promReduxConnect, withRouter } from "../util/utils";
import { ProjectLessonReader } from "../util/projectLessonRecorder";
import './courseIntroductionPage.scss'
import "./course.css"
import { ProjectExerciseViewerComponent } from "../util/projectEditor/projectExerciseViewer";
import { TASK_TYPE_EXERCISE_MULTIPLE_CHOICE_QUESTION, TASK_TYPE_EXERCISE_PYTHON_FILE 
 } from "../util/constants";
import { MultipleChoiceExerciseComponent } from "../util/multipleChoiceExercise";
import { promAxios } from "../../store/promAxios";
import { FileLessonReader } from "../util/fileLessonRecorder";
import { PythonExerciseViewerComponent } from "../util/pythonExerciseViewer";
import { checkLoginSaga, getCourseSaga, getCourseTaskSaga, getCourseTasksSaga, getExerciseSaga, getLessonContentSaga,
     logoutSaga, saveExerciseInSync, saveCourseTaskSaga, getSubscriptionsSaga } from "../../store/common.saga";
import { PromSpinnerComponent } from "../util/spinner";
import { HeaderComponent } from "../publicPage/HeaderComponent/headerComponent";
import { reduxStateUpdateAddOrder, reduxStateUpdateLoginStatus } from "../../store/common.reducer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown, faAngleUp, faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { faSquare, faCheckSquare } from "@fortawesome/free-regular-svg-icons";
import * as bs from 'bootstrap'
import styles from '../publicPage/HeaderComponent/headerComponent.module.scss'
import { Link } from "react-router-dom";


class CourseComponentInternal extends PureComponent {
    constructor(props) {
        super(props)
        this.state = {
            selectedTaskId: null,
            activeDropDown: false,
            selectedIndex: [],
            reportIssueBoxDisplayToggle: false,
            issueDropDown : false,
        }
    }

    componentDidMount() {
        this.props.dispatchFunctionAction(checkLoginSaga, {})
        this.parseUrl()
        if (!this.props.allSubscriptions) {
            this.props.dispatchFunctionAction(getSubscriptionsSaga, {})
        }
    }

    componentDidUpdate = (prevProps, prevState) => {
        this.parseUrl()
        if (this.state.courseId !== prevState.courseId) {
            this.getCourseIfNeeded()
        }
    }

    setSelectedTaskId = (taskId) => {
        const { courseId } = this.state
        this.props.navigate(`/courses?courseId=${courseId}&taskId=${taskId}`)
    }

    parseUrl = () => {
        const urlSearchParams = new URLSearchParams(this.props.location.search)
        const courseId = urlSearchParams.get("courseId")
        const taskId = urlSearchParams.get("taskId")

        this.setState({ selectedTaskId: taskId, courseId })
    }

    getCourseIfNeeded = () => {
        const { courseId } = this.state
        const { allCourses, allCoursesTasks } = this.props
        if (!courseId) {
            return
        }
        if (!allCourses?.[courseId]) {
            this.props.dispatchFunctionAction(getCourseSaga, {
                courseId
            })
        }

        if (!allCoursesTasks?.[courseId]) {
            this.props.dispatchFunctionAction(getCourseTasksSaga, {
                courseId
            })
        }
    }

    isCourseLoaded = () => {
        const { courseId } = this.state
        const { allCoursesTasks, allCourses } = this.props
        return !!allCoursesTasks?.[courseId] && !!allCourses?.[courseId]
    }

    logout = () => {
        this.props.dispatchFunctionAction(reduxStateUpdateLoginStatus, {
            loginStatus: "notLoggedIn", user: null
        })
        this.props.dispatchFunctionAction(logoutSaga, {})
    }

    getNextPreviousTasks = () => {
        const { allCoursesTasks, allCourses, modules, allSubscriptions } = this.props
        const { selectedTaskId, courseId } = this.state

        const courseContent = allCourses?.[courseId]

        let previousTask = null
        let nextTask = null

        if (!allCoursesTasks || !courseId || !selectedTaskId) {
            return
        }

        const allTasks = this.getAvailableTasks()

        const selectedTaskIndex = allTasks.findIndex(t => t.taskId === selectedTaskId)

        if (selectedTaskIndex > 0) {
            previousTask = allTasks[selectedTaskIndex - 1]
        }
        if (selectedTaskIndex >= 0 && selectedTaskIndex < allTasks.length) {
            nextTask = allTasks[selectedTaskIndex + 1]
        }

        return { previousTask, nextTask, courseId }
    }

    moveToPreviousTask = () => {
        const { previousTask, nextTask, courseId } = this.getNextPreviousTasks()
        this.setSelectedTaskId(previousTask?.taskId)
    }

    moveToNextTask = () => {
        const { previousTask, nextTask, courseId } = this.getNextPreviousTasks()
        if (nextTask) {
            this.setSelectedTaskId(nextTask?.taskId)
        }
    }

    logout = () => {
        this.props.dispatchFunctionAction(logoutSaga, {});
    }

    
    handleReportIssue = () => {
        this.setState((prevState) => (
            { reportIssueBoxDisplayToggle: ! prevState.reportIssueBoxDisplayToggle }
        ))
    };

    getTaskHtml = () => {
        const { allCoursesTasks, allCourses } = this.props
        const { courseId, selectedTaskId ,reportIssueBoxDisplayToggle, reportIssueMessageText ,reportIssueMessageStatusText, issueDropDown } = this.state
        const courseTasks = allCoursesTasks?.[courseId]
        const { nextTask } = this.getNextPreviousTasks()

        const isCourseLoaded = this.isCourseLoaded()

        const course = getCourseWithTaskStatus(allCourses?.[courseId], courseTasks)

        let selectedTask = null

        if (!course || !selectedTaskId) {
            return null
        }
        course.modules.forEach(module => {
            module.tasks.forEach((task) => {
                if (task.taskId === selectedTaskId) {
                    selectedTask = task
                }
            })
        })
        if (!selectedTask) {
            return null
        }

        const taskId = selectedTaskId

        return <div className="d-flex flex-column h-100 w-100">
            <div className="navBar d-flex align-items-center">
                <Link className="navLink d-flex flex-row align-items-center"
                    to={`/courses?courseId=${courseId}`}
                    style={{ marginLeft: "0.625rem" }}>
                    <svg aria-hidden="true" fill="none" focusable="false"
                        height="16" viewBox="0 0 16 16" width="16" id="cds-react-aria-732"
                        className="css-0"><path fillRule="evenodd" clipRule="evenodd"
                            d="M8 1.35l6.5 5.416V14.5h-13V6.766L8 1.349zM2.5 7.233V13.5h11V7.234L8 2.651 2.5 7.234z"
                            fill="currentColor"></path>
                        <path fillRule="evenodd" clipRule="evenodd" d="M5.5 8.5h5v6h-5v-6zm1 1v4h3v-4h-3z"
                            fill="currentColor"></path></svg></Link>
                <div style={{ marginLeft: "0.625rem" }}><svg aria-hidden="true" fill="none" focusable="false"
                    height="16" viewBox="0 0 16 16" width="16"
                    id="cds-react-aria-714" className="css-awzoag"><path
                        fillRule="evenodd" clipRule="evenodd"
                        d="M11.293 8L4.646 1.354l.708-.708L12.707 8l-7.353 7.354-.708-.707L11.293 8z"
                        fill="currentColor"></path></svg></div>
                <Link className="navLink d-flex flex-row align-items-center"
                    style={{ marginLeft: "0.625rem", textDecoration: 'none' }} to={
                        `/courses?courseId=${courseId}`
                    }>{selectedTask.moduleName}</Link>
                <div style={{ marginLeft: "0.625rem" }}><svg aria-hidden="true" fill="none" focusable="false"
                    height="16" viewBox="0 0 16 16" width="16"
                    id="cds-react-aria-714" className="css-awzoag"><path
                        fillRule="evenodd" clipRule="evenodd"
                        d="M11.293 8L4.646 1.354l.708-.708L12.707 8l-7.353 7.354-.708-.707L11.293 8z"
                        fill="currentColor"></path></svg></div>
                <div style={{ marginLeft: "0.625rem" }}>{selectedTask.taskName}</div>
                {/* <div style={{marginLeft: "0.625rem"}}>
                    <div className="primaryActionButton btn btn-primary">
                        Completed
                    </div>
                </div> */}
                {/*  */}
                {courseTasks?.[taskId]?.taskStatus === "passed" ?
                    <div style={{ marginLeft: "0.625rem" }}>
                        <div className="primaryActionButton greenActionButton">
                            Completed
                        </div>
                    </div> : null
                }
                <div className="me-auto"></div>
                {/* <div style={{marginLeft: "0.625rem"}}>
                    <div className="primaryActionButton redActionButton">
                        Mark as Incomplete
                    </div>
                </div> */}
                               
                <div className="ms-auto"></div>
                {courseTasks?.[taskId]?.taskStatus === "passed" &&
                    isLessonTask(selectedTask.taskType) ?
                    <div className="navLink redNavLink d-flex flex-row align-items-center"
                        onClick={() => {
                            this.props.dispatchFunctionAction(saveCourseTaskSaga, {
                                courseId, taskId: selectedTask.taskId,
                                courseTaskData: {
                                    taskStatus: "failed"
                                }
                            })
                        }}>
                        <div>Mark as Incomplete
                        </div>
                    </div> :
                    isLessonTask(selectedTask.taskType) &&
                        courseTasks?.[taskId]?.taskStatus !== "passed" ?
                        <div style={{ marginLeft: "0.625rem" }} onClick={() => {
                            this.props.dispatchFunctionAction(saveCourseTaskSaga, {
                                courseId, taskId: selectedTask.taskId,
                                courseTaskData: {
                                    taskStatus: "passed"
                                }
                            })
                        }}>
                            <div className="primaryActionButton">
                                Mark as Completed
                            </div>
                        </div> : null
                }

                <div className="d-flex align-items-center" style={{ marginLeft: "0.625rem", marginRight: "0.625rem" }}>
                    <div className="primaryActionButton d-flex flex-row align-items-center"
                        onClick={this.moveToPreviousTask}>
                        <div style={{ marginRight: "0.625rem", marginTop: "-0.1875rem" }}><svg aria-hidden="true"
                            fill="none" focusable="false"
                            height="16" viewBox="0 0 16 16" width="16" id="cds-react-aria-716"
                            className="css-0"><path fillRule="evenodd" clipRule="evenodd"
                                d="M4.707 8l6.647-6.646-.707-.708L3.292 8l7.353 7.354.708-.707L4.707 8z"
                                fill="currentColor"></path></svg>
                        </div>
                        <div>Previous</div>
                    </div>
                </div>
                <div className="d-flex align-items-center" style={{ marginLeft: "0.625rem", marginRight: "0.625rem" }}>
                    <div className="primaryActionButton d-flex flex-row align-items-center"
                        onClick={this.moveToNextTask}>
                        {nextTask && <div>Next</div>}
                        {nextTask && <div style={{ marginLeft: "0.625rem", marginTop: "-0.1875rem" }}><svg aria-hidden="true"
                            fill="none" focusable="false"
                            height="16" viewBox="0 0 16 16" width="16"
                            id="cds-react-aria-714" className="css-awzoag"><path
                                fillRule="evenodd" clipRule="evenodd"
                                d="M11.293 8L4.646 1.354l.708-.708L12.707 8l-7.353 7.354-.708-.707L11.293 8z"
                                fill="currentColor"></path></svg>
                        </div>}
                    </div>
                </div>
                <div 
                style={{
                    marginLeft: "0.625rem",
                    marginRight: "0.625rem",
                    display:"flex",
                    justifyContent:"right"
                }}
                >
                    <div style={{cursor:"pointer"}} onClick={()=>{this.setState({issueDropDown : !issueDropDown})}}>
                        <><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-three-dots-vertical" viewBox="0 0 16 16">
                            <path d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0m0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0m0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0"/>
                        </svg></>
                    </div>
                    {issueDropDown &&
                        <div className="issueButtonDropDown"
                        onClick={this.handleReportIssue}
                        style={{marginTop:"30px"}}
                        >
                            <div>Issue?</div>
                        </div>
                    }
                </div>
            </div>
            
            <hr style={{ margin: "0px" }}></hr>
            {isLessonTask(selectedTask.taskType) ?
                <LessonComponent courseId={courseId} lessonId={selectedTask.taskId}></LessonComponent> :
                selectedTask.taskType === "exam" ?
                    <ExamComponent courseId={courseId} examId={selectedTask.taskId}></ExamComponent> :
                    <ExerciseComponent courseId={courseId} exerciseId={selectedTask.taskId}></ExerciseComponent>
            }
            {reportIssueBoxDisplayToggle ?
                <ReportIssueComponent selectedTask={selectedTask} courseId={courseId} 
                handleReportIssue={this.handleReportIssue} /> : null}
        </div>
    }

    handleResumeCourseClick = () => {
        const { courseId, selectedTask } = this.state
        const { allCourses, loginStatus, allCoursesTasks, allSubscriptions } = this.props
        const courseTasks = allCoursesTasks?.[courseId]
        const allTasks = this.getAvailableTasks()
        for (const task of allTasks) {
            if (courseTasks?.[task?.taskId] && courseTasks?.[task?.taskId]?.taskStatus !== 'passed') {
                return this.setSelectedTaskId(task?.taskId)
            }
        }
    }

    getAvailableTasks = () => {
        const { allSubscriptions, allCourses, allCoursesTasks } = this.props
        const { courseId } = this.state
        const allTasks = []
        const courseContent = allCourses?.[courseId]
        const courseTasks = allCoursesTasks?.[courseId]
        const hasSubscription = isCourseAlreadySubscribed({ allSubscriptions, courseId })
        courseContent?.modules?.forEach((module) => {
            if (hasSubscription || module?.isTrialModule) {
                module?.tasks?.forEach((task) => {
                    allTasks.push(task)
                })
            }
        })
        return allTasks
    }

    onCreateOrder = (order) => {
        promAxios.post(`/api/v1/orders`, order).then(response => {
            this.props.dispatchFunctionAction(reduxStateUpdateAddOrder, { order: response.data })
            this.props.navigate(`/app/order?orderId=${response.data.orderId}`)
        })
    }

    render() {
        const { courseId, selectedTaskId ,reportIssueBoxDisplayToggle} = this.state
        const { allCourses, loginStatus, allSubscriptions, navigate } = this.props
        const isCourseLoaded = this.isCourseLoaded()
        if (loginStatus === "notLoggedIn") {
            navigate("/")
            return null
        }

        if (!courseId || !isCourseLoaded || !allCourses?.[courseId]) {
            return null
        }

        const { allCoursesTasks } = this.props
        const course = getCourseWithTaskStatus(
            allCourses?.[courseId], allCoursesTasks?.[courseId])

        const { selectedIndex, activeDropDown } = this.state;

        if (!courseId || !isCourseLoaded) {
            return null
        }
        const { totalModules, doneModules } = course
        if (selectedTaskId) {
            return this.getTaskHtml()
        }

        const subscription = getCourseSubscription(courseId, allSubscriptions)
        return (
            <>
                <HeaderComponent isLoggedIn={loginStatus === "loggedIn"} handleLogout={this.logout} />
                <div className="h-100 w-100">
                    <div className='course-header-container'>
                        <div className='course-content-container'>
                            <div className='course-title'>{course?.courseName}</div>
                            <div className='course-status'>
                                <button className='course-status-button action-button'
                                    onClick={this.handleResumeCourseClick}>Resume Course</button>
                                <div className='status category-level'>{doneModules} of&nbsp;
                                    {course?.modules?.length} Modules completed</div>
                            </div>
                        </div>
                    </div>

                    <div className='card-item-component'>
                        <div>
                            {course?.modules?.map((module, index) => (
                                <div className="course-card-item" style={{
                                    opacity: !subscription && !module.isTrialModule ? 0.3: 1}}
                                onClick={() => this.setState((prevState) => {
                                    if (!subscription && !module.isTrialModule) {
                                        const modal = new bs.Modal(document.getElementById(
                                            'registerForCourseErrorModal'))
                                        modal.show() 
                                        return
                                    }
                                    if (prevState?.selectedIndex?.includes(index)) {
                                        const selectedIndexesArray = selectedIndex?.filter((ele) => ele !== index)
                                        return {
                                            ...prevState,
                                            selectedIndex: selectedIndexesArray,
                                        }
                                    }
                                    return {
                                        ...prevState,
                                        selectedIndex: [...selectedIndex, index],
                                        activeDropDown: true,
                                    }
                                })}>
                                    <div className="card-item">
                                        <div className="card-title">Module {index + 1}: {module?.moduleName}</div>
                                        <div className="dropdown-card-icon">{(activeDropDown &&
                                            selectedIndex?.includes(index)) ?
                                            <FontAwesomeIcon icon={faAngleUp} /> :
                                            <FontAwesomeIcon icon={faAngleDown} />}</div>
                                    </div>
                                    <div className="card-status">
                                        <div className="completed-card-content">
                                            {module?.tasks.filter((task) => task?.taskStatus
                                                === 'passed').length}&nbsp;/&nbsp;{module?.tasks.length}
                                        </div>
                                    </div>
                                    {(activeDropDown && selectedIndex?.includes(index)) ?
                                        module.tasks.map((task, index) => {
                                            return <div className="course-tasks" key={index}onClick={() => {
                                                this.setSelectedTaskId(task?.taskId)
                                            }}>
                                                <div>
                                                    <div className="task-details d-flex align-items-center" 
                                                    style={{ marginBottom: '5px' }}>
                                                        {task?.taskStatus === 'passed' ? 
                                                        <div className="task-checkbox">
                                                            <FontAwesomeIcon icon={faCheckCircle} color={"green"}/>
                                                        </div> :
                                                        <div className="task-checkbox" style={{visibility: "collapse"}}>
                                                            <FontAwesomeIcon icon={faSquare} />
                                                        </div>}
                                                        <div className="task-name">
                                                        {`${index + 1}. `}
                                                        <span>{task.taskName}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        })
                                        : ' '}
                                </div>
                            ))}
                        </div>
                    </div>
                </div>

                <div className="modal fade" id="registerForCourseErrorModal" tabIndex="-1"
                    aria-labelledby="registerForCourseErrorModal1" aria-hidden="true">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            {!false ?
                            <div className="modal-body">
                                <label className="form-label m-3">Register for the course to try this module</label>
                            </div>: null}
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal"
                                    disabled={false}>Cancel</button>
                                <button type="button" className="btn btn-primary" data-bs-dismiss="modal"
                                    onClick={() => {
                                        navigate(`/app/register?courseId=${courseId}`)
                                        // this.onCreateOrder({
                                        //     orderItems: [{
                                        //         orderItemId: getRandomString(),
                                        //         orderItemType: "courseFee",
                                        //         courseId: courseContent.courseId,
                                        //         price: courseContent.price
                                        //     }]
                                        // })
                                    }}
                                    disabled={false}>Register</button>
                            </div>
                        </div>
                    </div>
                </div>

            </>
        )
    }
}

const mapStateToProps = state => {
    return {
        loginStatus: state.common.loginStatus,
        email: state.common.user?.email,
        userId: state.common.user?.userId,
        allCoursesTasks: state.common.allCoursesTasks,
        allCourses: state.common.allCourses,
        allSubscriptions: state.common.allSubscriptions
    };
};


export const CourseComponent = withRouter(promReduxConnect(CourseComponentInternal, mapStateToProps))


class ExerciseComponentInternal extends PureComponent {
    constructor(props) {
        super(props)
        this.state = {}
    }

    loadExercise = (forceReload) => {
        const { exerciseId, courseId, exercise } = this.props
        if (exerciseId && (forceReload || !exercise)) {
            this.props.dispatchFunctionAction(getExerciseSaga, { exerciseId, courseId })
        }
    }

    componentDidMount() {
        const { exerciseId, courseId } = this.props
        this.loadExercise()
        this.autoSaveExerciseInterval = setInterval(this.onAutoSaveExercise, 10000 * 1000)
    }

    componentDidUpdate(prevProps) {
        const { exerciseId, courseId, exercise } = this.props
        if (prevProps.exerciseId !== this.props.exerciseId) {
            this.loadExercise()
        }
    }

    componentWillUnmount = () => {
        if (this.autoSaveExerciseInterval) {
            clearInterval(this.autoSaveExerciseInterval)
        }
    }

    onAutoSaveExercise = () => {
        const { courseId, exerciseId } = this.props
        if (this.changedExerciseTask &&
            this.previousChangedExerciseTask !== this.changedExerciseTask) {
            saveExerciseInSync(
                courseId, exerciseId, this.changedExerciseTask,
                this.props.dispatchFunctionAction).then(responseData => {
                    if (this.props.onExerciseTaskProgressChanged) {
                        this.props.onExerciseTaskProgressChanged(null)
                    }
                })
            this.previousChangedExerciseTask = this.changedExerciseTask
        }
    }

    onExerciseTaskProgressChanged = (exerciseTask) => {
        this.changedExerciseTask = JSON.parse(JSON.stringify(exerciseTask))
        if (this.props.onExerciseTaskProgressChanged) {
            this.props.onExerciseTaskProgressChanged(exerciseTask)
        }
    }

    onSaveExercise = (exerciseData) => {
        const { courseId, exerciseId } = this.props
        console.log("onSaveExercise")
        this.props.dispatchFunctionAction(saveCourseTaskSaga, {
            courseId, taskId: exerciseId,
            courseTaskData: exerciseData
        })
    }

    onValidationResultsReceived = () => {
        const { courseId, exerciseId, exercise } = this.props
        if (exerciseId) {
            this.props.dispatchFunctionAction(getCourseTaskSaga, {
                courseId, taskId: exerciseId
            })
        }
    }

    render = () => {
        const { courseId, exerciseId, courseTask, exercise, isExam, email, allCoursesTasks } = this.props
        if (!courseId || !exerciseId || !exercise) {
            return null
        }
        const exerciseType = exercise.exerciseType || TASK_TYPE_EXERCISE_PYTHON_FILE

        return <div className="d-flex align-items-center p-0 flex-column flex-grow-1">
            {/* {isDevEmail(email) && <div><div className="primaryActionButton m-2"
                onClick={() => {
                    this.loadExercise(true)
                }}>Refresh</div></div>} */}
            {exerciseType === TASK_TYPE_EXERCISE_PYTHON_FILE ?
                <PythonExerciseViewerComponent
                    exercise={exercise}
                    onExerciseTaskProgressChanged={this.onExerciseTaskProgressChanged}
                    onValidationResultsReceived={this.onValidationResultsReceived}
                    courseId={courseId}
                    isExam={isExam}
                    onSaveExercise={this.onSaveExercise}
                    exerciseTask={courseTask}>

                </PythonExerciseViewerComponent> :
                isProjectExercise(exerciseType) ?
                    <ProjectExerciseViewerComponent
                        exercise={exercise}
                        courseId={courseId}
                        isExam={isExam}
                        onExerciseTaskProgressChanged={this.onExerciseTaskProgressChanged}
                        onSaveExercise={this.onSaveExercise}
                        exerciseTask={courseTask}>

                    </ProjectExerciseViewerComponent> :
                    exerciseType === TASK_TYPE_EXERCISE_MULTIPLE_CHOICE_QUESTION ?
                        <MultipleChoiceExerciseComponent
                            exercise={exercise}
                            courseId={courseId}
                            onExerciseTaskProgressChanged={this.onExerciseTaskProgressChanged}
                            isExam={isExam}
                            onSaveExercise={this.onSaveExercise}
                            exerciseTask={courseTask}>

                        </MultipleChoiceExerciseComponent> : null
            }
        </div>;
    }
    // "".toLowerCase
}


export const ExerciseComponent = promReduxConnect(ExerciseComponentInternal, (state, ownProps) => {
    return {
        courseTask: state.common.allCoursesTasks?.[ownProps.courseId]?.[ownProps.exerciseId],
        allCoursesTasks: state.common.allCoursesTasks,
        exercise: state.common.allExercises[ownProps.exerciseId],
        email: state.common.user?.email,
        userId: state.common.user?.userId
    };
})


class ExamComponentInternal extends PureComponent {
    constructor(props) {
        super(props)
        this.state = {
            selectedExerciseIndex: 0,
            changedExerciseTasks: {}
        }
    }

    componentDidMount() {
        this.updateTimeRemainingInternal = setInterval(this.updateTimeRemaining, 100)
        this.loadExam()
    }

    componentDidUpdate = (prevProps) => {
        if (this.props.examId !== prevProps.examId) {
            this.loadExam()
        }
    }

    loadExam = () => {
        const { examId, courseId, exam } = this.props
        if (examId && !exam) {
            this.props.dispatchFunctionAction(getExamSaga, { examId })
        }
    }

    componentWillUnmount = () => {
        if (this.updateTimeRemainingInternal) {
            clearInterval(this.updateTimeRemainingInternal)
        }
    }

    updateTimeRemaining = () => {
        const timeRemainingMillis = this.getExamTimeRemainingMillis()
        if (timeRemainingMillis > 0) {
            this.setState({ timeRemainingMillis })
        }
    }

    getTimeRemainingString = () => {
        const { timeRemainingMillis } = this.state

        const hours = Math.floor(timeRemainingMillis / (60 * 60 * 1000))
        const minutes = Math.floor((timeRemainingMillis - (hours * 60 * 60 * 1000)) / (60 * 1000))
        const seconds = Math.floor((timeRemainingMillis - (hours * 60 * 60 * 1000) - (minutes * 60 * 1000)) / (1000))

        let timeRemainingString = ""
        if (hours) {
            timeRemainingString = `${hours}:${minutes.toLocaleString('en-US', { minimumIntegerDigits: 2, useGrouping: false })}:${seconds.toLocaleString('en-US', { minimumIntegerDigits: 2, useGrouping: false })}:`
        } else {
            timeRemainingString = `${minutes}:${seconds.toLocaleString('en-US', { minimumIntegerDigits: 2, useGrouping: false })}`
        }

        return `${Math.ceil(timeRemainingMillis / 60000)}`
    }

    startExam = () => {
        const { courseId, examId, exam } = this.props

        promAxios.post(`/api/v1/exams/${examId}/start?courseId=${courseId}`,
            exam).then(response => {
                if (response && response.status === 200) {
                    this.props.dispatchFunctionAction(getCourseTasksSaga, {
                        courseId
                    })
                }
            })
    }

    submitExam = () => {
        const { courseId, exerciseId, examId, exam } = this.props

        Promise.all(Object.keys(this.state.changedExerciseTasks).map(exerciseId => {
            if (this.state.changedExerciseTasks[exerciseId]) {
                return saveExerciseInSync(
                    courseId, exerciseId, this.state.changedExerciseTasks[exerciseId],
                    this.props.dispatchFunctionAction)
            }
            return null
        }).filter(x => x)).then(() => {
            this.setState({ changedExerciseTasks: {} })

            this.setState({ isSubmitting: true }, () => {
                promAxios.post(`/api/v1/exams/${examId}/submit?courseId=${courseId}`,
                    exam).then(response => {
                        if (response && response.status === 200) {
                            this.props.dispatchFunctionAction(getCourseTasksSaga, {
                                courseId
                            })
                        }
                        this.setState({ isSubmitting: false })
                    })
            })
        })
    }

    onExerciseTaskProgressChanged = (exerciseId, exerciseTask) => {
        this.setState({
            changedExerciseTasks: {
                ...this.state.changedExerciseTasks,
                [exerciseId]: exerciseTask
            }
        })
    }

    getExamTimeRemainingMillis = () => {
        const { courseId, examId, courseTask, exam } = this.props
        if (!courseId || !examId) {
            return null
        }

        const { selectedExerciseIndex } = this.state

        const examTask = courseTask

        if (!exam) {
            return null
        }

        const currentTimeMillis = Date.now()
        const examStartTimestampMillis = examTask?.startTimestampMillis || 0
        const examMaxDurationMillis = exam?.maxDurationMillis || 0

        return examMaxDurationMillis - (currentTimeMillis - examStartTimestampMillis)
    }

    onSaveExam = () => {
        const { courseId, examId } = this.props
        Object.keys(this.state.changedExerciseTasks).forEach(exerciseId => {
            if (this.state.changedExerciseTasks[exerciseId]) {
                this.props.dispatchFunctionAction(saveCourseTaskSaga, {
                    courseId, taskId: exerciseId,
                    courseTaskData: this.state.changedExerciseTasks[exerciseId]
                })
            }
        })
        this.setState({ changedExerciseTasks: {} })
    }

    render = () => {
        const { courseId, examId, courseTask, exam } = this.props
        if (!courseId || !examId) {
            return null
        }

        const { selectedExerciseIndex, isSubmitting } = this.state

        const examTask = courseTask

        if (!exam) {
            return null
        }

        if (exam.uploadStatus !== "uploaded" || (exam.availableFromTimestampMillis > 0 &&
            new Date() < exam.availableFromTimestampMillis)) {
            return <div className="m-5 d-flex align-items-center flex-column">
                <div>This exam is not yet available.</div>
                {(exam.availableFromTimestampMillis || 0) > 0 ?
                    <div>Please check after {
                        new Date(exam.availableFromTimestampMillis).toLocaleString("en-IN")}</div> : null}
            </div>
        }


        const examStartTimestampMillis = examTask?.startTimestampMillis || 0
        const examValidateTimestampMillis = examTask?.validateTimestampMillis || 0
        const examMaxDurationMillis = exam?.maxDurationMillis || 0

        const timeRemainingMillis = this.getExamTimeRemainingMillis()

        const isOngoingExam = examStartTimestampMillis > 0 &&
            examValidateTimestampMillis < examStartTimestampMillis

        if (timeRemainingMillis < 0 && isOngoingExam) {
            return <div className="d-flex flex-column align-items-center">
                <div className="m-2">
                    You are past the deadline to work on this exam. You can
                    either start a fresh attempt or submit the previous unfinished attempt
                </div>
                <div><div className="primaryActionButton m-2"
                    onClick={this.submitExam}>Submit Last Attempt</div></div>
                <div><div className="primaryActionButton m-2" onClick={
                    this.startExam}>Start Fresh Attempt</div></div>
            </div>
        }



        // if (examStartTimestampMillis <= 0) {
        //     // exam not started
        //     return <div className="d-flex align-items-center">
        //         <div className="primaryActionButton m-5 ms-auto me-auto"
        //             onClick={() => {
        //                 this.startExam()
        //             }}
        //         >Start</div>
        //     </div>
        // }

        return <div className="d-flex flex-column p-0">
            {isSubmitting ?
                <PromSpinnerComponent isActive={isSubmitting}
                    messageText={"Submitting..."}></PromSpinnerComponent> : null}
            <div className="d-flex align-items-left flex-column p-0">
                <div className="p-4 ms-4 d-flex align-items-center" style={{}}>
                    <div style={{ fontSize: "1.875rem" }}>Introduction to Python Exam</div>
                    {!isOngoingExam ?
                        <div className="primaryActionButton ms-auto me-5" style={{}}
                            onClick={() => {
                                this.startExam()
                            }}
                        >Start</div> : null}
                    {isOngoingExam ?
                        Object.values(this.state.changedExerciseTasks || {}).filter(x => x).length > 0 ?
                            <button className="primaryActionButton saveExamButton ms-auto me-3"
                                onClick={this.onSaveExam}>
                                Save</button> :
                            <button className="primaryActionButton saveExamButton ms-auto me-3"
                                onClick={this.onSaveExam}
                                disabled>
                                Saved</button> : null}
                    {isOngoingExam ?
                        <button className="primaryActionButton me-5" onClick={this.submitExam}>
                            Submit</button> : null}
                </div>

                {isOngoingExam ?
                    <div className="ps-4 ms-4 pb-2 me-auto" style={{ textDecoration: "italic" }}>
                        {`Time remaining:  ${this.getTimeRemainingString()}
                         / ${(examMaxDurationMillis / 60000)} mins`}</div> : null}

                {!isOngoingExam ?

                    <div className="d-flex p-4 ms-4 p-0">
                        <table className="examTable">
                            <tr><td className="p-2">Total marks</td><td>{
                                (exam?.exercises || []).reduce((accumulatedValue, exercise) => {
                                    return exercise.marks + accumulatedValue
                                }, 0)}</td></tr>
                            <tr><td className="p-2">Last attempted</td><td>
                                {examTask?.validateTimestampMillis ?
                                    getDateString(examTask?.validateTimestampMillis) :
                                    "Not submitted yet"}</td></tr>
                            <tr><td className="p-2">Marks obtained in last attempt</td><td>{
                                examTask?.validateTimestampMillis ?
                                    (examTask?.validateMarks || 0) :
                                    "Not submitted yet"
                            }</td></tr>
                            <tr><td className="p-2">Max duration</td><td>{((
                                exam?.maxDurationMillis || 0) / 60000)} mins</td></tr>
                        </table>

                    </div> : null}
            </div>

            {isOngoingExam ?
                <div className="d-flex flex-column p-0">
                    <div className="ms-5 me-5 d-flex flex-wrap align-items-center">
                        {(exam?.exercises || []).map((exercise, exerciseIndex) => {
                            return <div className={selectedExerciseIndex === exerciseIndex ?
                                "examExerciseLinkSelected me-2" : "examExerciseLink me-2"}
                                onClick={() => {
                                    this.setState({ selectedExerciseIndex: exerciseIndex })
                                }}>
                                {`Exercise ${exerciseIndex + 1}`}</div>
                        })}
                    </div>
                    <ExerciseComponent courseId={courseId} exerciseId={
                        exam?.exercises?.[selectedExerciseIndex]?.exerciseId}
                        isExam={true}
                        onExerciseTaskProgressChanged={(exerciseTask) => {
                            this.onExerciseTaskProgressChanged(
                                exam?.exercises?.[selectedExerciseIndex]?.exerciseId,
                                exerciseTask)
                        }}></ExerciseComponent>
                </div> : null}
        </div>;
    }
}


export const ExamComponent = promReduxConnect(ExerciseComponentInternal, (state, ownProps) => {
    return {
        email: state.common.user?.email,
        userId: state.common.user?.userId,
        courseTask: state.common.allCoursesTasks?.[ownProps.courseId]?.[ownProps.examId],
        exam: state.common.allExams[ownProps.examId]
    };
})

class LessonComponentInternal extends PureComponent {
    constructor(props) {
        super(props)
        this.state = {}
    }

    componentDidMount() {
        const { lessonId, courseId } = this.props
        this.loadLessonContent()
    }

    componentDidUpdate = (prevProps) => {
        const { lessonId, courseId } = this.props
        const { allCoursesTasks } = this.props
        if (prevProps.lessonId !== this.props.lessonId) {
            this.loadLessonContent()
        }
    }

    loadLessonContent = () => {
        const { lessonId, courseId, lessonContent } = this.props
        if (lessonId && !lessonContent) {
            this.props.dispatchFunctionAction(getLessonContentSaga, { lessonId })
        }
    }

    render = () => {
        const { lessonId, courseId, lessonContent } = this.props
        const { allCoursesTasks } = this.props

        if (!lessonId || !lessonContent) {
            return null
        }
        const lessonType = lessonContent?.lessonType
        return <div className="p-0 h-100 w-100">
            {isProjectLesson(lessonType) ?
                <ProjectLessonReader lessonContent={lessonContent}></ProjectLessonReader> :
                <FileLessonReader lessonContent={lessonContent}></FileLessonReader>}
        </div>;
    }
}


export const LessonComponent = promReduxConnect(LessonComponentInternal, (state, ownProps) => {
    return {
        email: state.common.user?.email,
        userId: state.common.user?.userId,
        allCoursesTasks: state.common.allCoursesTasks,
        lessonContent: state.common.allLessonContents[ownProps.lessonId]
    };
})

class ReportIssueComponent extends PureComponent {
    constructor (props) {
        super(props)
        this.state = {
            reportIssueMessageText: "",
            reportIssueMessageStatusText: ""
        }
    }
    render = () => {
        const {selectedTask, courseId} = this.props
        return <div className="modal d-flex">
        <div className="modal-dialog w-100">
            <div className="modal-content">
                <div className="modal-header">
                    <h4 className="modal-title">Report Issue</h4>
                </div>
                <div className="modal-body">
                    <textarea 
                    className= "w-100"
                    placeholder="Kindly write your issue here!" 
                    value= {this.state.reportIssueMessageText}
                    onChange={e=>this.setState({reportIssueMessageText: e.target.value})}/>
                    <span>{this.state.reportIssueMessageStatusText}</span>
                </div>
                <div className="modal-footer">
                    <button type="button" className="btn btn-secondary" onClick= {
                    () => {
                    this.setState({
                        reportIssueMessageText: ""}),
                    this.props.handleReportIssue()
                    }}>Cancel</button>
                    <button type="button" className="btn btn-primary" onClick= {
                    () => {
                    if (this.state.reportIssueMessageText != "") {
                        promAxios.post("/api/v1/reportTaskIssue", {
                        taskId: selectedTask.taskId,
                        reportIssueMessageText: this.state.reportIssueMessageText,
                        courseId: courseId
                    })
                    .then((response) => {
                        if (response && response.status==200) {
                            this.setState({
                                reportIssueMessageStatusText: "Issue reported successfully!",
                                reportIssueMessageText: ""
                            })
                        }
                        else {
                            this.setState({
                                reportIssueMessageStatusText: "Issue not reported! Please try again."
                            })
                        }
                    }
                    )
                    .catch(() => {
                        this.setState({
                            reportIssueMessageStatusText: "Unable to report! Please try after some time."
                            })
                        }
                    )
                    }
                    else {
                        this.setState({
                            reportIssueMessageStatusText: "Please enter the issue!"
                        })
                    }
                    }}>Submit Issue</button>
                </div>
            </div>
        </div>
        </div>
    }
}