import { PureComponent, useRef } from "react"
import React from 'react';
import { promAxios } from "../../store/promAxios";

import { connect } from "react-redux";
import { Diff, diffChars, diffLines } from "diff"
import { EMPTY_ARRAY,getMainFileNameFromLessonContent,getRandomString } from "./utils";
import { PythonEditor } from "./pythonEditor";
import { JsEditor } from "./jsEditor";
import { TASK_TYPE_EXERCISE_PYTHON_FILE, TASK_TYPE_LESSON_HTML_FILE, TASK_TYPE_LESSON_JS_FILE, TASK_TYPE_LESSON_PYTHON_FILE } from "./constants";


import './fileLessonRecorder';
import "../dashboard/course.css";
import { HtmlEditor } from "./htmlEditor";


const addCharIndexToDiff = (diffOutput) => {
    let runningText = ""
    let charIndex = 0
    for (let i = 0; i < diffOutput.length; i++) {
        if (diffOutput[i].added) {
            charIndex = runningText.length
            runningText = runningText + diffOutput[i].value
        } else if (diffOutput[i].removed) {
            // runningText = runningText.substr(0, runningText.length - diffOutput[i].value.length)
            charIndex = runningText.length
        } else {
            charIndex = runningText.length
            runningText = runningText + diffOutput[i].value
        }
        diffOutput[i].charIndex = charIndex
    }
    return diffOutput
}

export const getLessonPartContentAccumulatedChanges = (lessonPartContent, changeIndex) => {
    let accumulatedText = ""
    for (let i = 0; i < lessonPartContent.changes.length && i <= changeIndex; i++) {
        const change = lessonPartContent.changes[i]
        const codeChanges = change.codeChanges || []
        for (let j = 0; j < codeChanges.length; j++) {
            if (codeChanges[j].operation === "add") {
                accumulatedText = accumulatedText.substr(0, codeChanges[j].charIndex) + codeChanges[j].text +
                    accumulatedText.substr(codeChanges[j].charIndex)
            } else {
                accumulatedText = accumulatedText.substr(0, codeChanges[j].charIndex) +
                    accumulatedText.substr(codeChanges[j].charIndex + codeChanges[j].text.length)
            }
        }
    }
    return getLessonPartContentAccumulatedChangesWithCharUpdates(lessonPartContent, changeIndex).accumulatedText
}

export const getLessonPartContentAccumulatedChangesWithCharUpdates = (lessonPartContent, changeIndex) => {
    let accumulatedText = ""
    let charUpdates = []
    for (let i = 0; i < lessonPartContent.changes.length && i <= changeIndex; i++) {
        const change = lessonPartContent.changes[i]
        const codeChanges = change.codeChanges || []
        for (let j = 0; j < codeChanges.length; j++) {
            if (codeChanges[j].operation === "add") {
                accumulatedText = accumulatedText.substr(0, codeChanges[j].charIndex) + codeChanges[j].text +
                    accumulatedText.substr(codeChanges[j].charIndex)
                if (i === changeIndex) {
                    charUpdates.push({
                        startCharIndex: codeChanges[j].charIndex,
                        endCharIndex: codeChanges[j].charIndex + codeChanges[j].text.length
                    })
                }
            } else {
                accumulatedText = accumulatedText.substr(0, codeChanges[j].charIndex) +
                    accumulatedText.substr(codeChanges[j].charIndex + codeChanges[j].text.length)
                // if (i === changeIndex) {
                //     charUpdates.push({
                //         startCharIndex: codeChanges[j].charIndex,
                //         endCharIndex: codeChanges[j].charIndex
                //     })
                // }
            }
        }
    }
    return { accumulatedText, charUpdates }
}

export const updateLessonPartContentChanges = (lessonPartContent, changeIndex, newCodeText) => {
    const accumulatedChangesTillPreviousEdit = getLessonPartContentAccumulatedChanges(lessonPartContent, changeIndex - 1)
    const diffOutput = addCharIndexToDiff(diffChars(accumulatedChangesTillPreviousEdit, newCodeText))
    const codeChanges = []
    diffOutput.forEach(diffOutputItem => {
        const { charIndex, value: text } = diffOutputItem
        if (diffOutputItem.added || diffOutputItem.removed) {
            codeChanges.push({
                charIndex,
                operation: diffOutputItem.added ? "add": "remove",
                text,
            })
        }
    })
    const newLessonPartContent = JSON.parse(JSON.stringify(lessonPartContent))
    newLessonPartContent.changes[changeIndex] = {
        ...newLessonPartContent.changes[changeIndex],
        codeChanges
    }
    return newLessonPartContent
}

class FileLessonRecorderComponent extends PureComponent {
    constructor(props) {
        super(props)
        this.state = {
            currentStepIndex: 0,
            lessonContent: {
                lessonId: getRandomString(16),
                lessonType: TASK_TYPE_LESSON_PYTHON_FILE,
                steps: [{}],
            },
            text: "",
            editorCodeText: "",
            textMode: "edit",
        }
        this.inputFile = React.createRef()
    }

    onEditorTextChange = (editorCodeText) => {
        if (editorCodeText !== this.getEditorText()) {
            this.setState({editorCodeText, textHighlights: []}, () => {
                const { lessonContent, currentStepIndex, editorCodeText, text } = this.state
                const newLessonContent = JSON.parse(JSON.stringify(lessonContent))
                newLessonContent.steps[currentStepIndex] = {
                    ...newLessonContent.steps[currentStepIndex],
                    files: [{
                        filePath: getMainFileNameFromLessonContent(lessonContent),
                        fileText: editorCodeText
                    }]
                }
                this.setState({
                    lessonContent: newLessonContent
                })
            })
        }
    }

    onNextClick = () => {
        const { lessonContent, currentStepIndex } = this.state
        if (currentStepIndex < lessonContent.steps.length - 1) {
            this.setState({
                currentStepIndex: currentStepIndex + 1,
                editorCodeText: ""
            })
        } else if (currentStepIndex === lessonContent.steps.length - 1) {
            const newLessonContent = JSON.parse(JSON.stringify(lessonContent))
            newLessonContent.steps[currentStepIndex + 1] = JSON.parse(JSON.stringify(
                lessonContent.steps[currentStepIndex]))
            this.setState({
                currentStepIndex: currentStepIndex + 1,
                lessonContent: newLessonContent,
                editorCodeText: ""
            })
        }
    }

    onDeleteClick = () => {
        const { lessonContent, currentStepIndex } = this.state
        if (lessonContent.steps.length <= 1) {
            return
        }
        
        let newCurrentStepIndex = currentStepIndex
        const newLessonContent = JSON.parse(JSON.stringify(lessonContent))
        newLessonContent.steps = [
            ...newLessonContent.steps.slice(0, currentStepIndex),
            ...newLessonContent.steps.slice(currentStepIndex+1)
        ]
        
        if (currentStepIndex === lessonContent.steps.length - 1) {
            newCurrentStepIndex = lessonContent.steps.length - 2
        }

        this.setState({
            currentStepIndex: newCurrentStepIndex,
            lessonContent: newLessonContent,
            editorCodeText: ""
        })
    }

    onInsertClick = () => {
        const { lessonContent, currentStepIndex } = this.state
        
        const newLessonContent = JSON.parse(JSON.stringify(lessonContent))
        newLessonContent.steps = [
            ...newLessonContent.steps.slice(0, currentStepIndex+1),
            JSON.parse(JSON.stringify(newLessonContent.steps[currentStepIndex])),
            ...newLessonContent.steps.slice(currentStepIndex+1)
        ]

        this.setState({
            lessonContent: newLessonContent,
            editorCodeText: ""
        })
    }

    onPreviousClick = () => {
        const { lessonContent, currentStepIndex } = this.state
        if (currentStepIndex > 0) {
            this.setState({
                currentStepIndex: currentStepIndex - 1,
                editorCodeText: "",})
        }
    }

    getEditorText = () => {
        const { lessonContent, currentStepIndex, editorCodeText } = this.state
        const fileText = lessonContent?.steps?.[currentStepIndex]?.files?.[0]?.fileText
        return editorCodeText || fileText || ""
    }

    getTextElements = () => {
        const { lessonContent, currentStepIndex } = this.state
        return lessonContent.steps[currentStepIndex]?.textItems || []
    }

    getOutputText = () => {
        const { lessonContent, currentStepIndex } = this.state
        return lessonContent.steps[currentStepIndex]?.outputText || ""
    }

    onAddNewText = () => {
        const { lessonContent, currentStepIndex } = this.state
        const newLessonContent = JSON.parse(JSON.stringify(lessonContent))
        const textItems = newLessonContent.steps[currentStepIndex]?.textItems || []
        textItems[textItems.length] = {html: ""}
        newLessonContent.steps[currentStepIndex] = {
            ...newLessonContent.steps[currentStepIndex],
            textItems
        }
        this.setState({lessonContent: newLessonContent})
    }

    onTextChange = (index, text) => {
        const { lessonContent, currentStepIndex } = this.state
        const newLessonContent = JSON.parse(JSON.stringify(lessonContent))
        const textItems = newLessonContent.steps[currentStepIndex]?.textItems || []
        textItems[index] = {html: text}
        newLessonContent.steps[currentStepIndex] = {
            ...newLessonContent.steps[currentStepIndex],
            textItems
        }
        this.setState({lessonContent: newLessonContent})
    }

    onRemoveText = (index) => {
        const { lessonContent, currentStepIndex } = this.state
        const newLessonContent = JSON.parse(JSON.stringify(lessonContent))
        let textItems = newLessonContent.steps[currentStepIndex]?.textItems || []
        textItems = [...textItems.slice(0, index), ...textItems.slice(index+1)]
        newLessonContent.steps[currentStepIndex] = {
            ...newLessonContent.steps[currentStepIndex],
            textItems
        }
        this.setState({lessonContent: newLessonContent})
    }

    onMergeWithPrevious = () => {
    }

    // componentWillUpdate(nextProps, nextState) {
    //     const stateKeys = new Set();
    //     const propsKeys = new Set();
    //     Object.keys(this.props).forEach(key => propsKeys.add(key));
    //     Object.keys(nextProps).forEach(key => propsKeys.add(key));
    //     Object.keys(this.state || {}).forEach(key => stateKeys.add(key));
    //     Object.keys(nextState || {}).forEach(key => stateKeys.add(key));

    //     const getDiff = (keys, object1, object2) => {
    //         const diffValues = [];
    //         const keysArray = [...keys];
    //         keysArray.filter(key => object1[key] !== object2[key]).forEach(key => diffValues.push([key, object1[key], object2[key]]));
    //         return [...diffValues];
    //     };

    //     console.log(getDiff(propsKeys, this.props, nextProps), getDiff(stateKeys, this.state || {}, nextState || {}));
    // }

    onMergeWithNext = () => {
    }

    onToggleView = () => {
        this.setState({textMode: (this.state.textMode === "edit" ? "view": "edit")})
    }

    onAddCode = () => {
        let textItemIndex = this.state?.textItemSelection?.textItemIndex 
        if (textItemIndex !== 0 && !textItemIndex) {
            return
        }

        const {
            selectionStartIndex=0, selectionEndIndex=0
        } = this.state?.textItemSelection || {}


        const { lessonContent, currentStepIndex } = this.state
        const newLessonContent = JSON.parse(JSON.stringify(lessonContent))
        if (!newLessonContent.steps[currentStepIndex]?.textItems ||
            !newLessonContent.steps[currentStepIndex]?.textItems?.[textItemIndex] ||
            !newLessonContent.steps[currentStepIndex]?.textItems?.[textItemIndex]?.html) {
            return
        }
        const text = newLessonContent.steps[currentStepIndex].textItems[textItemIndex].html
        if (selectionStartIndex === selectionEndIndex ||
            selectionStartIndex > text.length ||
            selectionEndIndex > text.length) {
            return
        }
        const replacedText = [
            text.slice(0, selectionStartIndex),
            "<code>",
            text.slice(selectionStartIndex, selectionEndIndex).split("<").join("&lt;").split(">").join("&gt;"),
            "</code>",
            text.slice(selectionEndIndex, text.length)
        ].join("")
        newLessonContent.steps[currentStepIndex].textItems[textItemIndex].html = replacedText
        this.setState({lessonContent: newLessonContent})
    }

    onDownloadClick = () => {
        const { lessonContent } = this.state

        const dataString = "data:text/json;charset=utf-8," + encodeURIComponent(JSON.stringify(lessonContent, null, 4));
        const downloadAnchorNode = document.createElement('a');
        downloadAnchorNode.setAttribute("href",     dataString);
        downloadAnchorNode.setAttribute("download", "lessonContent.json");
        document.body.appendChild(downloadAnchorNode);
        downloadAnchorNode.click();
        downloadAnchorNode.remove();
    }

    onLoadFileClick = () => {
        this.inputFile.current.click();
    }

    onChangeInputFile = (event) => {
        if (!event || !event.target || !event.target.files || event.target.files.length === 0) {
            return
        }

        const fileReader = new FileReader();
        fileReader.onload = (e) => {
            const lessonPartContent = JSON.parse(e.target.result)
            lessonPartContent.lessonType = TASK_TYPE_LESSON_PYTHON_FILE
            if (lessonPartContent.changes) {
                console.log("changed detected. old lesson")
                const changes = lessonPartContent.changes || []
                lessonPartContent.steps = []
                for (let changeIndex = 0; changeIndex < changes.length; changeIndex++) {
                    const codeText = getLessonPartContentAccumulatedChanges(lessonPartContent, changeIndex)
                    const textItems = changes[changeIndex]?.textChanges || []
                    const outputText = changes[changeIndex]?.outputText || ""
                    const textHighlights = changes[changeIndex]?.highlightBlocks || []

                    lessonPartContent.steps.push({})
                    lessonPartContent.steps[changeIndex] = {
                        ...lessonPartContent.steps[changeIndex],
                        textItems,
                        files: [{
                            // filePath: getMainFileNameFromLessonContent(lessonPartContent),
                            filePath: "main.py",
                            fileText: codeText
                        }],
                        textHighlights,
                        outputText
                    }
                }
                delete lessonPartContent.changes
            }

            this.setState({lessonContent: lessonPartContent})
        };
        fileReader.readAsText(event.target.files[0]);
    }

    onSelectionChanged = (selection) => {
        this.setState({currentSelection: selection})
    }

    onAddHighlight = (highlightMode) => {
        const { currentSelection, lessonContent, currentStepIndex, } = this.state
        if (currentSelection) {
            const textHighlights = this.getTextHighlights()
            const editorText = this.getEditorText() || ""
            const highlightIndices = []
            for (let i = 0; i < editorText.length; i++) {
                highlightIndices[i] = false
            }
            textHighlights.forEach(highlightBlock => {
                const { highlightStartIndex, highlightEndIndex } = highlightBlock
                for (let i = highlightStartIndex; i < highlightEndIndex && i < editorText.length; i++) {
                    highlightIndices[i] = true
                }
            })
            const { highlightStartIndex, highlightEndIndex } = currentSelection
            for (let i = highlightStartIndex; i < highlightEndIndex && i < editorText.length; i++) {
                highlightIndices[i] = !!(highlightMode === "highlight")
            }

            const newTextHighlights = []
            let currentStartIndex = -1
            for (let i = 0; i < highlightIndices.length; i++) {
                if (highlightIndices[i]) {
                    if (currentStartIndex === -1) {
                        currentStartIndex = i
                    }
                } else {
                    if (currentStartIndex !== -1) {
                        newTextHighlights.push({
                            highlightStartIndex: currentStartIndex,
                            highlightEndIndex: i
                        })
                        currentStartIndex = -1
                    }
                }
            }
            if (currentStartIndex !== -1) {
              newTextHighlights.push({
                  highlightStartIndex: currentStartIndex,
                  highlightEndIndex: highlightIndices.length
              })
            }

            const newLessonContent = JSON.parse(JSON.stringify(lessonContent))
            newLessonContent.steps[currentStepIndex] = {
                ...newLessonContent.steps[currentStepIndex],
                textHighlights: newTextHighlights
            }
            this.setState({lessonContent: newLessonContent})
        }
    }

    getTextHighlights = () => {
        const { lessonContent, currentStepIndex } = this.state

        return lessonContent.steps[currentStepIndex]?.textHighlights || EMPTY_ARRAY
    }

    onOutputTextChanged = (outputText) => {
        const { lessonContent, currentStepIndex } = this.state
        const newLessonContent = JSON.parse(JSON.stringify(lessonContent))
        newLessonContent.steps[currentStepIndex] = {
            ...newLessonContent.steps[currentStepIndex],
            outputText: outputText
        }
        this.setState({lessonContent: newLessonContent})
    }

    onLessonNameChange = (event) => {
        this.setState({
            lessonContent: {
                ...this.state.lessonContent,
                lessonName: event?.target?.value || ""
            }
        })
    }

    onGenerateNewLessonId = () => {
        this.setState({
            lessonContent: {
                ...this.state.lessonContent,
                lessonId: getRandomString(16)
            }
        })
    }

    setLessonType = (lessonType) => {
        const { lessonContent } = this.state
        const newLessonContent = JSON.parse(JSON.stringify(lessonContent))
        newLessonContent.lessonType = lessonType
        this.setState({lessonContent: newLessonContent, editorCodeText: ""})
    }

    render = () => {
        const { lessonContent, currentStepIndex, text, textMode, currentSelection } = this.state
        const textHighlights = this.getTextHighlights()
        const lessonType = lessonContent?.lessonType
        const textElements = this.getTextElements().map((text, index) => {
            return (<li key={index}>
                {textMode === "edit" ?
                <textarea type="textarea" className="form-control" 
                    onChange={(event) => this.onTextChange(index, event.target.value)}
                    onSelect={(event) => {
                        this.setState({
                            textItemSelection: {
                                textItemIndex: index,
                                selectionStartIndex: event.target.selectionStart,
                                selectionEndIndex: event.target.selectionEnd,
                            }
                        })
                    }}
                    value={text.html}/>:
                <div dangerouslySetInnerHTML={{__html: text.html}}/>}
                
                <button className="col-auto btn btn-primary" onClick={() => this.onRemoveText(index)}>Remove</button>
            </li>)
        })
        return (<div className="h-100 w-100">
            <div className="d-flex">
                <div className="input-group flex-grow-1">
                    <span className="input-group-text">Lesson Name</span>
                    <input type="text" className="form-control"
                        value={lessonContent.lessonName || ""} onChange={this.onLessonNameChange}/>
                </div>
                <div className="input-group dropdown ms-2">
                    <button className="btn btn-secondary recorderButton dropdown-toggle" type="button"
                        id="selectConfigMenuButton"
                        data-bs-toggle="dropdown" aria-expanded="false">
                        {lessonType}
                    </button>
                    <ul className="dropdown-menu" aria-labelledby="selectConfigMenuButton">
                        <li><a className="dropdown-item" onClick={() => this.setLessonType(
                            TASK_TYPE_LESSON_HTML_FILE)}>
                        htmlFile</a></li>
                        <li><a className="dropdown-item" onClick={() => this.setLessonType(
                            TASK_TYPE_LESSON_JS_FILE)}>
                        javascriptFile</a></li>
                        <li><a className="dropdown-item" onClick={() => this.setLessonType(
                            TASK_TYPE_EXERCISE_PYTHON_FILE)}>
                        pythonFile</a></li>
                    </ul>
                </div>
                <div className="input-group flex-grow-1">
                    <span className="input-group-text">Lesson Id</span>
                    <input type="text" className="form-control" value={lessonContent.lessonId || ""} disabled/>
                    <button className="btn btn-secondary recorderButton" onClick={this.onGenerateNewLessonId}>
                        New
                    </button>
                </div>
            </div>
            <div className="mb-2 d-flex">
                <button className="btn btn-primary ms-2 mb-2" onClick={this.onPreviousClick}
                    disabled={currentStepIndex <= 0}>Previous</button>
                <button className="btn btn-primary ms-2 mb-2" onClick={this.onNextClick}>
                    Next
                </button>
                <button className="btn btn-primary ms-2 mb-2" onClick={this.onInsertClick}>
                    Insert</button>
                <button className="btn btn-primary ms-2 mb-2" onClick={this.onDeleteClick}>
                    Delete</button>

                <div className="d-flex ms-auto" style={{height: "28px"}}>
                    <input type="text" className="form-control" value={this.state.loadLessonId || ""}
                        onChange={(e) => {
                            this.setState({loadLessonId: e.target.value})
                        }}/>
                </div>
                <button className="btn btn-primary ms-2 mb-2" onClick={(e) => {
                    if (!this.state.loadLessonId) {
                        return
                    }

                    promAxios.get(`/api/v1/lessonContents/${this.state.loadLessonId}`).then(response => {
                        this.setState({lessonContent: response.data})
                    })

                }}>Load From Server</button>
                <button className="btn btn-primary ms-2 mb-2" onClick={this.onLoadFileClick}>Load File</button>
                <button className="btn btn-primary ms-2 mb-2" onClick={this.onDownloadClick}>Download</button>
                <button className="btn btn-primary ms-2 mb-2" onClick={() => {
                    const lessonId = lessonContent?.lessonId || ""
                    if (!lessonId) {
                        return
                    }

                    promAxios.post(`/api/v1/lessonContents/${this.state.loadLessonId}`,
                        lessonContent).then(response => {
                    })
                }}>Save</button>
            </div>
            <br></br>
            <span>{this.state.currentStepIndex + 1}/{this.state.lessonContent.steps.length}</span><br></br>
            <button className="btn btn-primary ms-2 mb-2" onClick={this.onAddNewText}>Add New Text</button>
            <button className="btn btn-primary ms-2 mb-2" onClick={() => this.onAddHighlight("highlight")}>Add Highlight</button>
            <button className="btn btn-primary ms-2 mb-2" onClick={() => this.onAddHighlight("unhighlight")}>Remove Highlight</button>
            <button className="btn btn-primary ms-2 mb-2" onClick={this.onToggleView}>Toggle View</button>
            <button className="btn btn-primary ms-2 mb-2" onClick={this.onAddCode}>Add code</button>
            {/* <button className="btn btn-primary ms-2 mb-2" onClick={this.toggleHighlightMode}>{
            this.state.highlightMode === "highlight" ? "Un-Highlight": "Highlight"}</button> */}
            <ul className="mt-3">
                {textElements}
            </ul>
            <input type='file' id='file' ref={this.inputFile} style={{display: 'none'}} onChange={this.onChangeInputFile}/>
            {lessonType === TASK_TYPE_LESSON_HTML_FILE ?
            <HtmlEditor
                initialHtmlText={this.getEditorText()}
                height={"600px"}
                showOutput={true}
                onEditorTextChange={this.onEditorTextChange}
                highlightBlocks={textHighlights}
                onSelectionChanged={this.onSelectionChanged}
                allowCodeCopyPaste={true}
            ></HtmlEditor> : 
            lessonType === TASK_TYPE_LESSON_PYTHON_FILE ?
            <PythonEditor
                initialCodeText={this.getEditorText()}
                initialOutputText={this.getOutputText()}
                editorId={`${lessonContent?.lessonId || "lessonId"}-${currentStepIndex}`}
                // height={"600px"}
                showOutput={true}
                onEditorTextChange={this.onEditorTextChange}
                highlightBlocks={textHighlights}
                onSelectionChanged={this.onSelectionChanged}
                onOutputTextChanged={this.onOutputTextChanged}
                allowCodeCopyPaste={true}
                // exerciseId={lessonContent.lessonId}
            ></PythonEditor> :
            lessonType === TASK_TYPE_LESSON_JS_FILE ?
            <JsEditor
                initialCodeText={this.getEditorText()}
                initialOutputText={this.getOutputText()}
                height={"600px"}
                showOutput={true}
                onEditorTextChange={this.onEditorTextChange}
                highlightBlocks={textHighlights}
                onSelectionChanged={this.onSelectionChanged}
                onOutputTextChanged={this.onOutputTextChanged}
                allowCodeCopyPaste={true}
            ></JsEditor> : null
            }
        </div>)
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        email: state.common.user?.email,
        userId: state.common.user?.userId,
    };
};

const mapDispatchToProps = dispatch => ({
});

export const FileLessonRecorder = connect(
    mapStateToProps,
    mapDispatchToProps,
)(FileLessonRecorderComponent)

class FileLessonReaderComponent extends PureComponent {
    constructor(props) {
        super(props)
        this.state = {
            currentStepIndex: 0,
            lessonContent: {
                steps: [{}]
            }
        }
    }

    componentDidMount = (prevProps, prevState) => {
        this.setState({lessonContent: this.props.lessonContent})
    }

    componentDidUpdate = (prevProps, prevState) => {
        if (prevProps.lessonContent !== this.props.lessonContent) {
            this.setState({
                lessonContent: this.props.lessonContent,
                currentStepIndex: 0
            })
        }
    }

    onNextClick = () => {
        const { lessonContent } = this.state
        const { currentStepIndex } = this.state
        if (currentStepIndex < lessonContent.steps.length - 1) {
            this.setState({
                currentStepIndex: currentStepIndex + 1,
            })
        }
    }

    gettextHighlights = () => {
        const { currentStepIndex, editorHtmlText } = this.state
        const { lessonContent } = this.state
        return lessonContent.steps[currentStepIndex]?.textHighlights || EMPTY_ARRAY
    }

    onPreviousClick = () => {
        const { lessonContent } = this.state
        const { currentStepIndex, editorHtmlText } = this.state
        if (currentStepIndex > 0) {
            this.setState({
                currentStepIndex: currentStepIndex - 1,
            })
        }
    }

    getEditorText = () => {
        const { lessonContent, currentStepIndex, editorHtmlText } = this.state
        const fileText = (lessonContent?.steps?.[currentStepIndex]?.files ||[]).find(
            x => x.filePath === getMainFileNameFromLessonContent(lessonContent))?.fileText
        return editorHtmlText || fileText || ""
    }

    getTextElements = () => {
        const { lessonContent } = this.state
        const { currentStepIndex, editorHtmlText } = this.state
        return lessonContent.steps[currentStepIndex]?.textItems || []
    }

    getOutputText = () => {
        const { lessonContent } = this.state
        const { currentStepIndex, editorHtmlText } = this.state
        return lessonContent.steps[currentStepIndex]?.outputText || ""
    }
    render = () => {
        const { lessonContent } = this.state
        const { currentStepIndex, editorHtmlText } = this.state
        const textHighlights = this.gettextHighlights()
        const textElements = this.getTextElements().map((text, index) => {
            return (<li key={index} className="">
                <div dangerouslySetInnerHTML={{__html: text.html}}/>
            </li>)
        })

        const lessonType = lessonContent?.lessonType
        return (<div className="d-flex flex-column w-100 h-100">
            <div className="d-flex align-items-center ms-3 mt-1">
                <div><h3 className="m-3">{lessonContent.lessonName}</h3></div>
                <button className="primaryActionButton" style={{marginLeft: "10px"}}
                    onClick={this.onPreviousClick}
                    disabled={currentStepIndex <= 0}
                >Previous</button>
                <button className="primaryActionButton"
                    onClick={this.onNextClick} style={{marginLeft: "10px"}}
                    disabled={currentStepIndex >= lessonContent.steps.length - 1}>Next
                </button>
                {/* <div className="primaryActionButton">
                    <button className={currentStepIndex > 0 ?
                    "btn btn-primary col-1": "btn btn-primary col-1 disabled"}
                    onClick={this.onPreviousClick}>Previous</button></div>
                <div className="primaryActionButton">
                    <button className={currentStepIndex < lessonContent.steps.length - 1 ?
                    "btn btn-primary col-1": "btn btn-primary col-1 disabled"}
                    onClick={this.onNextClick}>Next</button>
                </div> */}
            </div>

            <ul className="mt-3 ms-3" style={{fontSize: "22px", maxWidth: "700px"}}>
                {textElements}
            </ul>

            {lessonType === TASK_TYPE_LESSON_HTML_FILE ?
            <HtmlEditor
                initialHtmlText={this.getEditorText()}
                height={"600px"}
                showOutput={true}
                highlightBlocks={textHighlights}
            ></HtmlEditor> : 
            lessonType === TASK_TYPE_LESSON_PYTHON_FILE ?
            <PythonEditor
                initialCodeText={this.getEditorText()}
                initialOutputText={this.getOutputText()}
                editorId={`${lessonContent?.lessonId || "lessonId"}-${currentStepIndex}`}
                height={"600px"}
                showOutput={true}
                highlightBlocks={textHighlights}
            ></PythonEditor> :
            lessonType === TASK_TYPE_LESSON_JS_FILE ?
            <JsEditor
                initialCodeText={this.getEditorText()}
                initialOutputText={this.getOutputText()}
                height={"600px"}
                showOutput={true}
                highlightBlocks={textHighlights}
            ></JsEditor> : null
            }
        </div>)
    }
}

const mapStateToPropsFileLessonReader = (state, ownProps) => {
    return {
        email: state.common.user?.email,
        userId: state.common.user?.userId,
    };
};

const mapDispatchToPropsFileLessonReader = dispatch => ({
});


export const FileLessonReader = connect(
    mapStateToPropsFileLessonReader,
    mapDispatchToPropsFileLessonReader,
)(FileLessonReaderComponent)