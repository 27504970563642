import { PureComponent } from "react"
import React from 'react';

import Editor from "@monaco-editor/react";
import * as monaco from "monaco-editor"
import { getPositionIndexFromLineAndColumnNumbers, getPositionIndicesforRange } from "../utils";
import { getHighlightIndicesforRange, getHighlightRangeForHighlightIndices } from "../pythonEditor";

import "../../dashboard/course.css"

export class ProjectCodeEditorComponent extends PureComponent {
    constructor(props) {
        super(props);
        this.currentEditorText = ""
    }

    componentDidMount = () => {
    }

    componentDidUpdate = (prevProps) => {
        const { textHighlights, fileText } = this.props

        if (fileText !== prevProps.fileText || textHighlights !== prevProps.textHighlights) {
            this.updateEditor()
        }
    }

    // componentWillUpdate(nextProps, nextState) {
    //     const stateKeys = new Set();
    //     const propsKeys = new Set();
    //     Object.keys(this.props).forEach(key => propsKeys.add(key));
    //     Object.keys(nextProps).forEach(key => propsKeys.add(key));
    //     Object.keys(this.state || {}).forEach(key => stateKeys.add(key));
    //     Object.keys(nextState || {}).forEach(key => stateKeys.add(key));

    //     const getDiff = (keys, object1, object2) => {
    //         const diffValues = [];
    //         const keysArray = [...keys];
    //         keysArray.filter(key => object1[key] !== object2[key]).forEach(
    //             key => diffValues.push([key, object1[key], object2[key]]));
    //         return [...diffValues];
    //     };

    //     console.log(getDiff(propsKeys, this.props, nextProps), 
    //     getDiff(stateKeys, this.state || {}, nextState || {}));
    // }


    onEditorChange = (editorText, event) => {
        const { fileText } = this.props
        this.currentEditorText = editorText

        if (fileText !== editorText) {
            this.props.onFileTextEdited(editorText)
        }
    }

    onEditorMount = (editor, monaco) => {
        const { hideLineNumbers, readOnly } = this.props
        this.monacoEditor = editor
        const monacoOptions = {
            minimap: {
                enabled: false
            },
            wordWrap: "on",
            readOnly: !!readOnly
        }
        if (hideLineNumbers) {
            monacoOptions.lineNumbers = "off",
            monacoOptions.glyphMargin = false,
            monacoOptions.folding = false
            monacoOptions.lineDecorationsWidth = 0
            monacoOptions.lineNumbersMinChars = 0
        }
        this.monacoEditor.updateOptions(monacoOptions)
        this.monacoEditor.onDidChangeCursorSelection(this.onDidChangeCursorSelection)
        this.updateEditor()
    }

    updateEditor = () => {
        const { fileText } = this.props
        if (!this.monacoEditor || !this.monacoEditor.setValue) {
            return
        }

        if (fileText !== this.currentEditorText) {
            console.log({fileText, a: this.currentEditorText})
            this.currentEditorText = fileText || ""
            this.monacoEditor.setValue(fileText || "")
        }

        this.updateDecorations()
    }

    updateDecorations = () => {
        const { fileText, editorLanguage } = this.props
        if (!this.monacoEditor || !this.monacoEditor.setValue) {
            return
        }
        const textHighlights = this.props.textHighlights || []
        console.log({textHighlights, fileText})
        const decorations = []
        textHighlights.forEach(highlight => {
            const { highlightStartIndex, highlightEndIndex } = highlight
            if (highlightStartIndex < 0 || highlightEndIndex < 0 || highlightStartIndex === highlightEndIndex) {
                return
            }
            const { startLineNumber, startColumnNumber, endLineNumber, endColumnNumber } =
                getHighlightRangeForHighlightIndices(
                highlightStartIndex, highlightEndIndex, fileText || "")
            decorations.push({ 
                range: new monaco.Range(startLineNumber, startColumnNumber, endLineNumber, endColumnNumber),
                options: {
                    inlineClassName: 'myInlineDecoration'
                }
            })
        })
        if (!this.oldDecorations) {
            this.oldDecorations = this.monacoEditor.deltaDecorations([], decorations)
        } else {
            this.oldDecorations = this.monacoEditor.deltaDecorations(this.oldDecorations, decorations)
        }
    }

    onDidChangeCursorSelection = (event) => {
        const { fileText, editorLanguage } = this.props
        if (!this.props.onTextSelectionChanged) {
            return
        }
        const selections = this.monacoEditor.getSelections() || []
        if (selections.length < 1) {
            return
        }
        const selection = selections[0]
        const { startLineNumber, startColumn: startColumnNumber,
             endLineNumber, endColumn: endColumnNumber } = selection
        const { highlightStartIndex, highlightEndIndex } = getHighlightIndicesforRange(
            startLineNumber, startColumnNumber, endLineNumber, endColumnNumber, fileText || "")
        if (this.props.onTextSelectionChanged && highlightStartIndex !== highlightEndIndex) {
            this.props.onTextSelectionChanged(highlightStartIndex, highlightEndIndex)
        }
    }

    render = () => {
        const { fileText, editorLanguage } = this.props
        if (!fileText && fileText !== "") {
            return null
        }
        
        return <div style={{height: "100%"}}>
            <Editor
                height="100%"
                defaultLanguage={editorLanguage || "python"}
                language={editorLanguage || "python"}
                onChange={this.onEditorChange}
                onMount={this.onEditorMount}
            />
        </div> 
    }
}

