import { PureComponent } from "react"
import React from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import { faTrash, faLightbulb, faSave, faTerminal, faTimesCircle, faWindowMaximize } from '@fortawesome/free-solid-svg-icons'
import { faCompressArrowsAlt } from '@fortawesome/free-solid-svg-icons'
import * as monaco from "monaco-editor"
import { EMPTY_ARRAY, EMPTY_OBJECT, getHtmlString, getRandomString, promReduxConnect } from "../utils";

import * as bs from 'bootstrap'

import "../../dashboard/course.css"
import { runHttpApiSaga } from "../../../store/common.saga";
import Editor from "@monaco-editor/react";
import { ProjectCodeEditorComponent } from "./projectCodeEditor";





class ApiPlaygroundComponent extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            httpRequestMethod: "GET",
            httpRequestBodyText: "",
            httpUrlPath: "",
            httpRequestHeaders: [],
            selectedRequestTab: 'body',
            selectedResponseTab: 'body'
        }
        this.dropdownRef = React.createRef();
    }

    // componentWillUpdate(nextProps, nextState) {
    //     const stateKeys = new Set();
    //     const propsKeys = new Set();
    //     Object.keys(this.props).forEach(key => propsKeys.add(key));
    //     Object.keys(nextProps).forEach(key => propsKeys.add(key));
    //     Object.keys(this.state || {}).forEach(key => stateKeys.add(key));
    //     Object.keys(nextState || {}).forEach(key => stateKeys.add(key));

    //     const getDiff = (keys, object1, object2) => {
    //         const diffValues = [];
    //         const keysArray = [...keys];
    //         keysArray.filter(key => object1[key] !== object2[key]).forEach(key =>
    //             diffValues.push([key, object1[key], object2[key]]));
    //         return [...diffValues];
    //     };

    //     console.log(getDiff(propsKeys, this.props, nextProps),
    //         getDiff(stateKeys, this.state || {}, nextState || {}));
    // }

    handleClick = (e) => {
        if(this.dropdownRef?.current && !this.dropdownRef?.current.contains(e?.target)){
            this.setState({
                isHttpMethodDropdownOpen : false
            })
        }
    }

    componentDidMount = () => {
        this.setHttpRequestInfo()
    }

    componentDidUpdate = (prevProps) => {
        // if (this.props.httpUrl !== prevProps.httpUrl) {
        //     this.setState({editedHttpUrl: ""})
        // }
        this.onClickEventListener = document.addEventListener('mousedown',this.handleClick)
        if (prevProps.httpRequestInfo !== this.props.httpRequestInfo) {
            this.setHttpRequestInfo()
        }

        // httpRequestInfo = {
        //     "httpRequestMethod": "POST",
        //     "httpRequestBody": "",
        //     "httpUrlPath": "is_even",
        //     "httpResponseBody": {
        //         "is_even": true
        //     },
        //     "httpResponseStatus": 200,
        //     "httpRequestHeaders": [
        //         {
        //             "httpRequestHeaderKey": "Authorization",
        //             "httpRequestHeaderValue": "24"
        //         }
        //     ]
        // }
    }

    componentWillUnmount(){
        if(this.onClickEventListener){
            document.removeEventListener(this.onClickEventListener)
        }
    }

    setHttpRequestInfo = () => {
        const { httpRequestInfo } = this.props
        const { httpUrlPath="", httpRequestMethod="GET", httpRequestBody, httpRequestHeaders=[] } =
            httpRequestInfo || {}

        const httpRequestBodyText = httpRequestBody ? JSON.stringify(httpRequestBody): ""

        this.setState({
            httpUrlPath, httpRequestMethod, httpRequestBodyText, httpRequestHeaders
        })
    }

    runHttpApi = () => {
        const { processId, processName } = this.props
        const { httpUrlPath, httpRequestMethod, httpRequestBodyText, httpRequestHeaders } = this.state
        const runId = getRandomString()

        console.log({httpUrlPath, httpRequestMethod, httpRequestBodyText, httpRequestHeaders})

        this.setState({runId}, () => {
            this.props.onRunHttpApi({
                runId, processId, httpUrlPath, httpRequestMethod,
                httpRequestBodyText, httpRequestHeaders
            })
        })
    }

    getHttpResponseBodyText = () => {
        const { runHttpApiResponses, httpRequestInfo } = this.props
        const { runId } = this.state
        const httpResponseBody = runHttpApiResponses?.[runId]?.httpResponseBody || httpRequestInfo?.httpResponseBody
        console.log({httpResponseBody, a: JSON.stringify(httpResponseBody)})
        if (httpResponseBody && typeof(httpResponseBody) === "string") {
            return httpResponseBody
        } else if (httpResponseBody) {
            return JSON.stringify(httpResponseBody)
        } else {
            return ""
        }
    }

    getHttpResponseStatus = () => {
        const { runHttpApiResponses, httpRequestInfo } = this.props
        const { runId } = this.state
        return runHttpApiResponses?.[runId]?.httpResponseStatus || httpRequestInfo?.httpResponseStatus || ""
    }

    render = () => {
        const { 
            processName,
            runHttpApiResponses
        } = this.props
        const { runId, httpRequestMethod, httpRequestBodyText, httpRequestHeaders=[],
            httpUrlPath=""
        } = this.state
        const httpResponseStatus = this.getHttpResponseStatus()
        const getHttpResponseBodyText = this.getHttpResponseBodyText()

        console.log({runHttpApiResponses,httpResponseStatus, getHttpResponseBodyText, a: this.props.httpRequestInfo})

        return (
        <div className="h-100 w-100 d-flex flex-column p-2 httpApiPlaygroundTab position-relative" style={{}}>
            <div className="d-flex httpApiPlaygroundUrlContainer align-items-center"
                style={{
                    
                }}>

                    <div className="httpApiPlaygroundMethodButton d-flex align-items-center" style={{
                        }}
                        onClick={() => {
                            this.setState({isHttpMethodDropdownOpen: !this.state.isHttpMethodDropdownOpen})
                        }}>
                        <span className="flex-grow-1 text-center">{httpRequestMethod || "GET"}</span>
                    </div>
                <input type="text" className="ps-1 flex-grow-1 httpApiPlaygroundUrlInput" id="httpUrl"
                    spellcheck="false"
                    style={{
                        height: "100%",
                    }}
                    placeholder="" value={`${processName}/${httpUrlPath}`} onChange={(e) => {
                        const newHttpUrlPath = e.target?.value?.slice(processName.length + 1)
                        this.setState({httpUrlPath: newHttpUrlPath})
                    }} />
                <button className="httpApiPlaygroundMethodButton"
                    style={{
                        // width: "6rem",
                        // padding: "0px",
                        // height: "25px",
                    }}
                    disabled={!!this.props.disableRequests}
                    onClick={this.runHttpApi}>Send</button>
            </div>
            {this.state.isHttpMethodDropdownOpen && <div className="d-flex flex-column httpApiPlaygroundMethodDropdown" ref={this.dropdownRef}>
                <button className="dropdown-item" onClick={() =>{
                    this.setState({httpRequestMethod: "GET", isHttpMethodDropdownOpen: false})}}>GET</button>
                <button className="dropdown-item" onClick={() =>{
                    this.setState({httpRequestMethod: "POST", isHttpMethodDropdownOpen: false})}}>POST</button>
                <button className="dropdown-item" onClick={() =>{
                    this.setState({httpRequestMethod: "DELETE", isHttpMethodDropdownOpen: false})}}>DELETE</button>
            </div>}

            <div className="d-flex flex-grow-1 flex-column httpApiPlaygroundRequestContainer">
                <div className="d-flex httpApiPlaygroundRequestTabGroup"
                    >
                    <button className={
                        this.state.selectedRequestTab === "body" ?
                            "httpApiPlaygroundRequestTabItem httpApiPlaygroundRequestTabItemSelected": "httpApiPlaygroundRequestTabItem"
                    } onClick={() => {
                        this.setState({selectedRequestTab: "body"})
                    }} sele>Body</button>
                    <button className={
                        this.state.selectedRequestTab === "headers" ?
                            "httpApiPlaygroundRequestTabItem httpApiPlaygroundRequestTabItemSelected": "httpApiPlaygroundRequestTabItem"
                    } onClick={() => {
                        this.setState({selectedRequestTab: "headers"})
                    }}>Headers</button>
                </div>
                {this.state.selectedRequestTab === "headers" ? <div className="d-flex flex-column">
                    {[...httpRequestHeaders, {httpRequestHeaderKey: "", httpRequestHeaderValue: ""}].map(
                        (httpRequestHeader, httpRequestHeaderIndex) => {
                        const { 
                            httpRequestHeaderKey="", httpRequestHeaderValue=""
                        } = httpRequestHeader

                        return <div className="d-flex" key={`httpRequestHeaderKey-${httpRequestHeaderIndex}`}>
                            <input type="text" className="flex-grow-1 httpApiPlaygroundRequestHeaderInput" id="httpUrl"
                                style={{
                                    // height: "25px",
                                    // alignSelf: "center"
                                }}
                                placeholder="" value={httpRequestHeaderKey} onChange={(event) => {
                                    const newHttpRequestHeaders = [...httpRequestHeaders]
                                    console.log({httpRequestHeaders, newHttpRequestHeaders})
                                    if (!newHttpRequestHeaders[httpRequestHeaderIndex]) {
                                        newHttpRequestHeaders[httpRequestHeaderIndex] = {httpRequestHeaderKey: "", httpRequestHeaderValue: ""}
                                    }
                                    newHttpRequestHeaders[httpRequestHeaderIndex
                                    ].httpRequestHeaderKey = event.target.value || ""
                                    this.setState({httpRequestHeaders: newHttpRequestHeaders})
                                }} />
                                
                            <input type="text" className="flex-grow-1 httpApiPlaygroundRequestHeaderInput" id="httpUrl"
                                style={{
                                    // height: "25px",
                                    // marginLeft: "5px",
                                    // alignSelf: "center"
                                }}
                                placeholder="" value={httpRequestHeaderValue} onChange={(event) => {
                                    console.log({httpRequestHeaders})
                                    const newHttpRequestHeaders = [...httpRequestHeaders]
                                    if (!newHttpRequestHeaders[httpRequestHeaderIndex]) {
                                        newHttpRequestHeaders[httpRequestHeaderIndex] = {httpRequestHeaderKey: "", httpRequestHeaderValue: ""}
                                    }
                                    newHttpRequestHeaders[httpRequestHeaderIndex
                                    ].httpRequestHeaderValue = event.target.value || ""
                                    this.setState({httpRequestHeaders: newHttpRequestHeaders})
                                }} />
                            <button className="httpApiPlaygroundHeaderTrashButton"
                                style={{
                                    visibility: httpRequestHeaderIndex < (httpRequestHeaders || []).length ? "visible": "hidden"
                                }}
                                ><FontAwesomeIcon icon={faTrash} style={{color: "#8C8F9B"}} onClick={() => {
                                    this.setState({httpRequestHeaders: [
                                        ...httpRequestHeaders.slice(0, httpRequestHeaderIndex),
                                        ...httpRequestHeaders.slice(httpRequestHeaderIndex+1)
                                    ]})
                                }}/></button>
                        </div>
                    })}
                </div>: null}

                {this.state.selectedRequestTab === "body" ? 
                <div className="httpApiPlaygroundRequestBodyContainer flex-grow-1">
                    <div className="h-100 position-relative">
                    <ProjectCodeEditorComponent
                        fileText={httpRequestBodyText || ""}
                        editorLanguage={'javascript'}
                        onFileTextEdited={(text) => this.setState({httpRequestBodyText: text})}
                        onTextSelectionChanged={() => {}}
                        textHighlights={[]}
                        hideLineNumbers={true}
                    ></ProjectCodeEditorComponent></div>
                </div>
                : null}
            </div>
            <div className="d-flex flex-column httpApiPlaygroundResponseContainer">
                <div className="d-flex httpApiPlaygroundRequestTabGroup"
                    >
                    <button className={
                        this.state.selectedResponseTab === "body" ?
                            "httpApiPlaygroundRequestTabItem httpApiPlaygroundRequestTabItemSelected": "httpApiPlaygroundRequestTabItem"
                    } onClick={() => {
                        this.setState({selectedResponseTab: "body"})
                    }}>Body</button>
                    <div className="ms-auto"></div>
                    { (httpResponseStatus > 0 || httpResponseStatus === -1 || true) && 
                        <div className="d-flex align-items-center">
                            <span className="httpApiPlaygroundResponseAttributeKeyText">Status:</span>
                            <span className="httpApiPlaygroundResponseAttributeValueText">{httpResponseStatus}</span>
                        </div>
                    }
                    { "todo" && 
                    <div className="d-flex align-items-center">
                        <span className="httpApiPlaygroundResponseAttributeKeyText">Time:</span>
                        <span className="httpApiPlaygroundResponseAttributeValueText">102ms</span>
                    </div>}
                    { "todo" && 
                    <div className="d-flex align-items-center">
                        <span className="httpApiPlaygroundResponseAttributeKeyText">Size:</span>
                        <span className="httpApiPlaygroundResponseAttributeValueText">103kb</span>
                    </div>}
                    <span className="me-2"></span>
                </div>

                {this.state.selectedResponseTab === "body" ? 
                <div className="httpApiPlaygroundRequestBodyContainer flex-grow-1">
                    <div className="h-100 position-relative">
                    <ProjectCodeEditorComponent
                        fileText={getHttpResponseBodyText}
                        editorLanguage={'javascript'}
                        onFileTextEdited={() => {}}
                        onTextSelectionChanged={() => {}}
                        textHighlights={[]}
                        hideLineNumbers={true}
                        readOnly={true}
                    ></ProjectCodeEditorComponent></div>
                </div>
                : null}
            </div>
        </div>)
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        email: state.common.user?.email,
        userId: state.common.user?.userId,
    };
};

export const ApiPlayground = promReduxConnect(ApiPlaygroundComponent, 
    mapStateToProps
)